import React, { useState, createContext, useEffect, useContext } from "react";
import { fetchGet, postRequest } from "../utils/api_helper";
import { GloabalContext } from "../layout/Index";
import { useLocation } from "react-router";

export const MyContext = createContext();

export const ContextMailProvider = ({ children }) => {
  const location = useLocation();
  const { gotNotification } = useContext(GloabalContext);

  const [suggestionMails, setSuggestionMails] = useState([]);

  const [checkMail, setCheckMail] = useState([]);
  const [onEditor, setOnEditor] = useState(true);

  // const [checkHead, setCheckHead] = useState(false);
  const [deleteMail, setDeleteMail] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [signature, setSignature] = useState(true);
  const [storageFiledAlert, setStorageFiledAlert] = useState(false);
  const [countRefresh, setCountRefresh] = useState(false);
  const [attachmentList, setAttachmentList] = useState([]);
  const [messageView, setMessageView] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const [labels, setLabels] = useState([]);
  const [aside, setAside] = useState(false);

  const toggleMessageView = () => {
    if (messageView) {
      // fetchTabData();
      // setRefresh(true);
    }
    setMessageView(!messageView);
  };

  const [recentDelete, setRecentDelete] = useState(null);
  const [recentRead, setRecentRead] = useState([]);

  const [draftComposeData, setDraftComposeData] = useState(null);
  const [draftComposeModal, setDraftComposeModal] = useState(false);

  const [quickInsertsList, setQuickInsertsList] = useState(null);
  const [signatureList, setSignatureList] = useState(null);

  const getQuickInsertsList = async () => {
    const data = new FormData();
    data.append("type", 0);
    const response = await postRequest("/mail/get-snippet", data);
    setQuickInsertsList(response?.data);
  };

  const getSignatureList = async () => {
    const data = new FormData();
    data.append("type", 1);
    const response = await postRequest("/mail/get-snippet", data);
    setSignatureList(response?.data);
  };

  const openDraftCompose = (item) => {
    setDraftComposeData(item);
    setDraftComposeModal(true);
  };

  const closeDraftCompose = () => {
    setDraftComposeData(null);
    setDraftComposeModal(false);
  };

  const allSuggestions = async () => {
    const fdata = new FormData();
    fdata.append("suggestion", "@");
    try {
      const response = await postRequest("/mail/get-suggestions", fdata);
      setSuggestionMails(response.data.map((elem) => ({ ...elem, value: elem.text })));
    } catch (error) {
      // console.error("Error fetching suggestions:", error);
    }
  };

  useEffect(() => {
    allSuggestions();
    getQuickInsertsList();
    getSignatureList();
  }, []);

  useEffect(() => {
    fetchTabData();
    setRefresh(true);
  }, [gotNotification]);

  useEffect(() => {
    const handlePopState = () => {
      const urlParams = new URLSearchParams(window.location.search);
      const id = parseInt(urlParams.get("msg-id"), 10);

      if (id) {
        setSelectedId(id);
        setMessageView(true);
      } else {
        setSelectedId(null);
        setMessageView(false);
      }
    };

    handlePopState();

    // Add event listener for 'popstate'
    window.addEventListener("popstate", handlePopState);

    // Cleanup the event listener on unmount
    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, [location]);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);

    if (messageView && selectedId) {
      // Add or update msg-id query parameter
      urlParams.set("msg-id", selectedId);
      // Construct the new URL with updated query parameters
      const newUrl = `${window.location.pathname}?${urlParams.toString()}`;
      window.history.pushState({ path: newUrl }, "", newUrl);
    } else {
      // Remove msg-id query parameter
      urlParams.delete("msg-id");
      // Construct the new URL with updated query parameters
      const newUrl = `${window.location.pathname}`;
      window.history.pushState({ path: newUrl }, "", newUrl);
    }
  }, [messageView, selectedId]);

  const [tabCounts, setTabCounts] = useState({
    Inbox: 0,
    Draft: 0,
    Trash: 0,
    Spam: 0,
  });

  const fetchTabData = async () => {
    const response = await fetchGet("/mail/show-labels-count");
    if (response?.status) {
      setTabCounts({
        Inbox: response.inbox_count,
        Draft: response.draft_count,
        Trash: response.trash_count,
        Spam: response.spam_count,
      });
    }
  };
  const onEditorTool = () => {
    setOnEditor(!onEditor);
  };

  useEffect(() => {
    fetchTabData();
  }, [countRefresh]);

  return (
    <MyContext.Provider
      value={{
        onEditor,
        aside,
        setAside,
        setOnEditor,
        checkMail,
        setCheckMail,
        deleteMail,
        setDeleteMail,
        refresh,
        setRefresh,
        messageView,
        setMessageView,
        toggleMessageView,
        selectedId,
        setSelectedId,
        openDraftCompose,
        closeDraftCompose,
        draftComposeData,
        draftComposeModal,
        setDraftComposeModal,
        recentDelete,
        setRecentDelete,
        recentRead,
        setRecentRead,
        countRefresh,
        setCountRefresh,
        tabCounts,
        fetchTabData,
        attachmentList,
        setAttachmentList,
        onEditorTool,
        signature,
        setSignature,
        labels,
        setLabels,
        suggestionMails,
        quickInsertsList,
        getQuickInsertsList,
        signatureList,
        getSignatureList,
      }}
    >
      {children}
    </MyContext.Provider>
  );
};

import React, { useContext, useEffect, useState } from "react";
import classNames from "classnames";
import Toggle from "../sidebar/Toggle";
import User from "./dropdown/user/User";
import Notification from "./dropdown/notification/Notification";
import { Icon } from "../../components/Component";
import { useTheme, useThemeUpdate } from "../provider/Theme";
import { useLocation } from "react-router";
import AppDropdown from "./dropdown/app/App";
import "../../pages/css/common.css";
import silocloudwhite from "../../images/applogos/white_theme/silocloud_white.png";
import silocloudblack from "../../images/applogos/black_theme/silocloud_black.png";
import mobileLogo from "../../images/applogos/mail-logo.png";
import { Link } from "react-router-dom";
import { mail_apps, mail_apps_logo, mail_apps_logo_b, mail_apps_logo_w } from "../../images/applogos";
import { fetchAPI } from "../../utils/Utils";
import VoiceComponent from "../../pages/VoiceComponent";
import { GloabalContext } from "../Index";
const Header = ({ fixed, className }) => {
  const { query, setQuery, aside, setAside } = useContext(GloabalContext);
  const theme = useTheme();
  const { skin } = useTheme();
  const themeUpdate = useThemeUpdate();
  const headerClass = classNames({
    "nk-header": true,
    "nk-header-fixed": fixed,
    [`is-light`]: theme.header === "white",
    [`is-${theme.header}`]: theme.header !== "white" && theme.header !== "light",
    [`${className}`]: className,
  });

  const [fullScreenOn, setFullScreenOn] = useState(false);
  useEffect(() => {
    const fetchTheme = async () => {
      try {
        const data = new FormData();
        data.append("action", "fetch");
        const response = await fetchAPI(data, "api/v1/public/frontend-settings");
        if (response.status) {
          const fetchedTheme = response?.data?.frontend_settings?.theme;
          themeUpdate.skin(fetchedTheme === "1" ? "light" : "dark");
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchTheme();
  }, [fullScreenOn]);

  const location = useLocation();
  const toggleFullScreen = () => {
    setFullScreenOn(!fullScreenOn);
    if (
      document.fullscreenElement ||
      document.webkitFullscreenElement ||
      document.mozFullScreenElement ||
      document.msFullscreenElement
    ) {
      // Exit fullscreen mode
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      }
    } else {
      const element = document.documentElement;
      if (element.requestFullscreen) {
        element.requestFullscreen();
      } else if (element.webkitRequestFullscreen) {
        element.webkitRequestFullscreen();
      } else if (element.mozRequestFullScreen) {
        element.mozRequestFullScreen();
      } else if (element.msRequestFullscreen) {
        element.msRequestFullscreen();
      }
    }
  };

  // State for time and date
  const [time, setTime] = useState("");
  const [date, setDate] = useState("");

  useEffect(() => {
    const updateTime = () => {
      const now = new Date();
      const hours = now.getHours();
      const minutes = now.getMinutes();
      const ampm = hours >= 12 ? "PM" : "AM";
      const formattedHours = hours % 12 || 12;
      const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;

      setTime(`${formattedHours}:${formattedMinutes} ${ampm}`);

      const days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
      const day = days[now.getDay()];
      const date = now.getDate();
      const month = now.getMonth() + 1;
      const year = now.getFullYear();

      setDate(`${day}, ${month}/${date}/${year}`);
    };

    updateTime();
    const interval = setInterval(updateTime, 1000);

    return () => clearInterval(interval);
  }, []);

  const onSearchChange = (e) => {
    let value = e.target.value;
    setQuery(value);
  };

  return (
    <section className="section-header">
      <div className={`${headerClass} border-0`}>
        <div className="container-fluid px-0 px-md-2">
          <div className="nk-header-wrap align-items-center py-1">
            <div className="nk-header-app-name">
              <div className="nk-menu-trigger d-lg-none ms-n1 ms-0 mx-1">
                <Toggle
                  className="nk-nav-toggle nk-quick-nav-icon"
                  icon="menu"
                  click={() => {
                    setAside(!aside);
                  }}
                />
              </div>
              <Link to={process.env.REACT_APP_PUBLIC_URL} className="d-none d-sm-block">
                <div className="">
                  <img
                    height="40px"
                    width={123}
                    style={{ objectFit: "contain" }}
                    src={skin === "light" ? mail_apps_logo_w : mail_apps_logo_b}
                  />
                </div>
              </Link>
              <Link to={process.env.REACT_APP_PUBLIC_URL} className="d-sm-none flex-shrink-0">
                <img height="30px" width={"30px"} src={mobileLogo} />
              </Link>
              <div className="form-control-wrap ms-2 ms-md-4 header-search">
                <div className="form-icon form-icon-left">
                  <Icon name="search" />
                </div>
                <input
                  className="form-control"
                  type="text"
                  id="appsearch"
                  placeholder="Search"
                  value={query}
                  onChange={(e) => onSearchChange(e)}
                  style={{ borderRadius: "24px" }}
                />
                <div className="form-icon form-icon-right pointer">
                  {/* <Icon name="mic" /> */}
                  <VoiceComponent />
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-center header-calendar">
              <div style={{ lineHeight: "17px" }}>
                <p className="mb-0 fw-bolder text-blue-color">{time}</p>
                <p className="fs-9px mb-0">{date}</p>
              </div>
              <div className="ms-1">
                <Link to="https://calendar.silocloud.io/">
                  <Icon name="calender-date" className="head-icon" />
                </Link>
              </div>
            </div>

            <div className="nk-header-tools align-items-center py-0">
              <ul className="nk-quick-nav">
                <li className="mt-1 d-none d-md-block">
                  {fullScreenOn ? (
                    <Icon name="minimize" className="pointer fs-4" onClick={toggleFullScreen} />
                  ) : (
                    <Icon name="maximize" className="pointer fs-4" onClick={toggleFullScreen} />
                  )}
                </li>
                <li className="notification-dropdown">
                  <Notification />
                </li>
                <li className="notification-dropdown">
                  <AppDropdown />
                </li>
                <li className="user-dropdown d-flex">
                  <User />
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default Header;

import React, { useContext, useEffect, useState } from "react";
import SimpleBar from "simplebar";
import { Icon, PreviewCard, TooltipComponent, UserAvatar } from "../../components/Component";

import parse from "html-react-parser";
import {
  Badge,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Spinner,
  Toast,
  ToastBody,
  UncontrolledDropdown,
} from "reactstrap";
import archive from "../../images/applogos/images/archive.png";
import unarchive from "../../images/applogos/images/unarchive.png";
import trash from "../../images/applogos/images/trash.png";
import trashBody from "../../images/applogos/images/trashbody.png";
import arrow_down from "../../images/applogos/images/arrow_down.png";
import view from "../../images/applogos/images/view.png";
import MarkAsRead from "../../images/applogos/images/mark-as-read.png";
import MarkAsUnRead from "../../images/applogos/images/mark-as-unread.png";
import attach from "../../images/applogos/images/attach.png";
import restore from "../../images/applogos/images/restore.png";
import notSpam from "../../images/applogos/images/back.png";

import { findUpper, formatDateTime, getCookie } from "../../utils/Utils";
import { MyContext } from "../ContextMail";
import { Link, useLocation, useNavigate } from "react-router-dom";
import InboxMessages from "../app/inbox/InboxMessages";
import { postRequest } from "../../utils/api_helper";
import Shimmer from "../Shimmer";
import { GloabalContext } from "../../layout/Index";
import { toast } from "react-toastify";

const formatRecipientName = (recipient) => {
  const username = getCookie("username"); // Get the username from the cookie

  // Get display name based on firstname and lastname
  const displayName = recipient.firstname
    ? `${recipient.firstname.charAt(0).toUpperCase() + recipient.firstname.slice(1)} ${
        recipient.lastname ? recipient.lastname.charAt(0).toUpperCase() + recipient.lastname.slice(1) : ""
      }`
    : recipient.name || recipient.mail.split("@")[0];

  // Show 'me' if the recipient's name matches the user's name
  return username === recipient.name ? "me" : displayName.trim();
};

// Function to render recipients with optional prefix (for 'cc:' and 'bcc:')
const renderEmails = (recipients, prefix = "") => {
  if (!recipients || recipients.length === 0) return null;

  return (
    <>
      {recipients.map((recipient, index) => (
        <span key={index}>
          {prefix} {formatRecipientName(recipient)}
          {index < recipients.length - 1 ? ", " : ""}
        </span>
      ))}
    </>
  );
};

const RecipientsDisplay = ({ reply }) => {
  const { recipient, cc, bcc } = reply || {};

  return (
    <>
      <span>To:</span> {renderEmails(recipient)}
      {cc && cc.length > 0 && <>, {renderEmails(cc, "CC:")}</>}
      {bcc && bcc.length > 0 && <>, {renderEmails(bcc, "BCC:")}</>}
    </>
  );
};

const ContentBody = ({ data, setData, emptyMessage }) => {
  const {
    checkMail,
    setCheckMail,
    checkHead,
    setCheckHead,
    refresh,
    setRefresh,
    setSelectedId,
    toggleMessageView,
    openDraftCompose,
    recentDelete,
    recentRead,
    setRecentRead,
    fetchTabData,
    toggleRefresh,
  } = useContext(MyContext);

  // console.log(formatDateTime("2024-08-24 18:50:14"));

  const { setReadNotification } = useContext(GloabalContext);

  useEffect(() => {
    if (recentDelete && data?.length > 0) {
      setData((prevData) => prevData?.filter((item) => item.id !== recentDelete));
    }
  }, [recentDelete]);

  useEffect(() => {
    if (recentRead?.length > 0 && data?.length > 0) {
      let updatedData = data.map((item) => {
        // Check if the item's ID exists in the recentRead array
        if (recentRead.includes(item.id) && item?.message) {
          // Mark the message as read
          return {
            ...item,
            message: {
              ...item.message,
              meta: {
                ...item.message.meta,
                read: true,
              },
            },
          };
        }
        return item; // Return unchanged item if ID is not found
      });

      setData(updatedData); // Update the state with modified data
    }
  }, [recentRead, data]);

  const checkMessage = (id, isChecked) => {
    let updatedCheckedMail = [...checkMail];

    // Update the checked mail list
    if (isChecked) {
      if (!updatedCheckedMail.includes(id)) {
        updatedCheckedMail.push(id);
      }
    } else {
      updatedCheckedMail = updatedCheckedMail.filter((mailId) => mailId !== id);
    }

    // Update the data with the new checked status
    let updatedData = data.map((item) => {
      if (item.id === id) {
        item.message.meta.checked = isChecked;
      }
      return item;
    });

    setData([...updatedData]);
    setCheckMail(updatedCheckedMail);
  };

  // A separate function to handle the "check all" toggle
  // const toggleCheckAll = () => {
  //   setCheck(!check);
  // };

  // const { setToastVisible } = useContext(GloabalContext);
  const [archived, setArchived] = useState(false);

  const location = useLocation(); // Get the current route

  const isOnTrashPage = location.pathname === "/trash";
  const isOnSpamPage = location.pathname === "/spam";

  const onFavoriteClick = async (id) => {
    let defaultData = data;
    let item = defaultData.findIndex((item) => item.id === id);
    defaultData[item].message.meta.favourite = !defaultData[item].message.meta.favourite;
    setData([...defaultData]);
    try {
      const data = new FormData();
      data.append("favourite_id", id);
      const response = await postRequest("/mail/favourite-inbox-list-email", data);
      if (response?.status) {
        if (location.pathname === "/favourite" && !defaultData[item].message.meta.favourite) {
          setTimeout(() => {
            defaultData = defaultData.filter((elem, index) => index !== item);
            setData([...defaultData]);
          }, 500);
        }
      }
    } catch (error) {
      console.log("error message", error);
    }
  };

  const onSpamClick = async (id) => {
    let defaultData = data;
    try {
      const data = new FormData();
      data.append("email_id", id);
      const response = await postRequest("/mail/undo-spam-email", data);
      if (response?.status) {
        fetchTabData();
        defaultData = defaultData.filter((elem, index) => elem.id !== id);
        setData([...defaultData]);
        toggleRefresh();
      }
    } catch (error) {
      console.log("error message", error);
    }
  };

  const onRestoreClick = async (id) => {
    let defaultData = data;
    try {
      const data = new FormData();
      data.append("email_id", id);
      const response = await postRequest("/mail/undo-trash-email", data);
      if (response?.status) {
        fetchTabData();
        defaultData = defaultData.filter((elem, index) => elem.id !== id);
        setData([...defaultData]);
        toggleRefresh();
      }
    } catch (error) {
      console.log("error message", error);
    }
  };

  const onArchiveClick = async (id) => {
    let defaultData = data;
    let item = defaultData.findIndex((item) => item.id === id);
    defaultData[item].message.meta.archived = !defaultData[item].message.meta.archived;
    setData([...defaultData]);
    try {
      const data = new FormData();
      data.append("archieve_id", id);
      const response = await postRequest("/mail/change-archive-status", data);
      if (response?.status) {
        fetchTabData();
        if (location.pathname !== "/all-mails") {
          setTimeout(() => {
            defaultData = defaultData.filter((elem, index) => index !== item);
            setData([...defaultData]);
          }, 500);
        } else {
        }
      }
    } catch (error) {
      console.log("error message", error);
    }
  };

  const onListClick = (item) => {
    setSelectedId(item.id);
    toggleMessageView();
  };

  const onDeleteClick2 = async (id) => {
    let defaultData = data;
    let item = defaultData.findIndex((item) => item.id === id);
    defaultData[item].message.meta.trash = !defaultData[item].message.meta.trash;
    setData([...defaultData]);
    try {
      const data = new FormData();
      data.append("email_id", id);
      const response = await postRequest("/mail/delete-email", data);
      if (response?.status) {
        setTimeout(() => {
          defaultData = defaultData.filter((elem, index) => index !== item);
          setData([...defaultData]);
        }, 500);
      }
    } catch (error) {
      console.log("error message", error);
    }
  };

  const onDeleteClick = async (id) => {
    setReadNotification((prevNotifications) => [...prevNotifications, id]);
    const oldData = [...data]; // Save the original data for undo
    let itemIndex = oldData.findIndex((item) => item.id === id);

    if (itemIndex === -1) return; // If no matching item is found, exit early

    // // Toggle trash state locally
    // oldData[itemIndex].message.meta.trash = !oldData[itemIndex].message.meta.trash;
    // setData([...oldData]); // Update UI immediately

    try {
      const formData = new FormData();
      formData.append("email_id", id);

      // Promise toast for deleting email
      const deletePromise = postRequest("/mail/delete-email", formData);

      toast.promise(deletePromise, {
        pending: "Deleting email...",
        success: {
          render({ data }) {
            // On successful deletion, remove the item from the data array
            const updatedData = oldData.filter((item, index) => index !== itemIndex);
            setData(updatedData);

            // Define the undo function
            const undoMessageSeen = async () => {
              const undoData = new FormData();
              undoData.append("email_id", id);

              const response = await postRequest("/mail/undo-trash-email", undoData);

              if (response.status) {
                setData(oldData); // Restore the original data
                toast.success("Email restored successfully!", { autoClose: 3000 });
              } else {
                toast.error("Failed to restore the email.", { autoClose: 3000 });
              }
            };

            return (
              <div>
                <span>{data.message || "Email deleted successfully!"}</span>
                {isOnTrashPage || isOnSpamPage ? (
                  ""
                ) : (
                  <Link
                    to=""
                    className="ms-3"
                    onClick={(e) => {
                      e.preventDefault();
                      undoMessageSeen();
                    }} // Attach the function here
                  >
                    Undo
                  </Link>
                )}
              </div>
            );
          },
          autoClose: 5000, // Auto close after 5 seconds
        },
        error: "Failed to delete email.",
      });
    } catch (error) {
      console.log("Error deleting email:", error);
      toast.error("An error occurred while deleting the email.");
    }
  };

  const seMarkAsRead = async (id) => {
    setRecentRead([]);
    let defaultData = data;
    let item = defaultData.findIndex((item) => item.id === id);
    let isRead = defaultData[item].message.meta.read;
    defaultData[item].message.meta.read = !isRead;
    setData([...defaultData]);
    try {
      const data = new FormData();
      data.append("read_id", id);
      data.append("action", !isRead ? "read" : "unread");
      const response = await postRequest("/mail/read-email", data);
      if (response.status) fetchTabData();
    } catch (error) {
      console.log("error message", error);
    }

    // const data = new FormData();
    // data.append("", id);
    // const response = postRequest("/mail/read-email", data);
  };

  const getUniqueNames = (data) => {
    const username = getCookie("username"); // Get the username from the cookie

    // Collect unique names
    const uniqueNames = [
      ...new Set(
        data
          .filter((item) => item.isRecipient)
          .map((item) => {
            // Use item.firstname if available, otherwise fallback to item.name
            const displayName = item.firstname
              ? item.firstname.charAt(0).toUpperCase() + item.firstname.slice(1)
              : item.name;

            return displayName === username ? "me" : displayName;
          })
      ),
    ];

    // Determine the output format based on the number of unique names
    if (uniqueNames.length === 1) {
      const singleName = uniqueNames[0];
      const item = data.find(
        (item) =>
          (item.firstname && singleName === item.firstname.charAt(0).toUpperCase() + item.firstname.slice(1)) ||
          singleName === item.name
      );

      return item && item.lastname
        ? `${singleName.charAt(0).toUpperCase() + singleName.slice(1)} ${
            item.lastname.charAt(0).toUpperCase() + item.lastname.slice(1)
          }`
        : singleName;
    } else if (uniqueNames.length > 1) {
      return uniqueNames
        .map((name) => {
          const item = data.find(
            (item) =>
              (item.firstname && name === item.firstname.charAt(0).toUpperCase() + item.firstname.slice(1)) ||
              name === item.name
          );

          return item && item.lastname
            ? `${name.charAt(0).toUpperCase() + name.slice(1)} ${item.lastname.charAt(0).toUpperCase()}`
            : name;
        })
        .join(", ");
    }

    return "";
  };

  const stripHtmlTags = (html) => {
    return html
      .replace(/<\/?[^>]+(>|$)/g, " ")
      .replace(/\s+/g, " ")
      .trim();
  };

  return (
    <React.Fragment>
      <div className="nk-ibx-list">
        {/* {data === null || refresh ? ( */}
        {data === null ? (
          <div className="">
            <Shimmer />
          </div>
        ) : data?.length > 0 ? (
          data.map(
            (item) =>
              item?.message?.reply.length > 0 && (
                <div className={`nk-ibx-item ${item?.message?.meta?.read ? "is-read" : " is-unread"}`} key={item.id}>
                  <a
                    // href="#item"
                    onClick={(ev) => {
                      // openDraftCompose(item);
                      ev.preventDefault();
                      if (item?.message?.meta.draft) {
                        openDraftCompose(item);
                      } else onListClick(item);

                      // onListClick(ev, item);

                      // navigate(`view/${item.id}`);
                    }}
                    className="nk-ibx-link current"
                  >
                    <span></span>
                  </a>
                  <div className="nk-ibx-item-elem nk-ibx-item-check">
                    <div className="custom-control custom-control-sm custom-checkbox">
                      <input
                        type="checkbox"
                        checked={checkMail.includes(item.id)}
                        className="custom-control-input nk-dt-item-check"
                        id={`conversionItem${item.id}`}
                        onChange={(e) => checkMessage(item.id, e.target.checked)}
                      />
                      <label className="custom-control-label" htmlFor={`conversionItem${item?.id}`}></label>
                    </div>
                  </div>
                  <div className="nk-ibx-item-elem nk-ibx-item-star">
                    <div className="asterisk">
                      <a
                        href="#item"
                        className={item?.message?.meta?.favourite ? "active" : ""}
                        onClick={(ev) => {
                          ev.preventDefault();
                          onFavoriteClick(item.id);
                        }}
                      >
                        <Icon name={`${item?.message?.meta?.favourite ? "star-fill" : "star"}`}></Icon>
                      </a>
                    </div>
                  </div>

                  {item?.message?.meta.draft ? (
                    <div className="nk-ibx-item-elem nk-ibx-item-user">
                      <p className="text-danger">Draft</p>
                    </div>
                  ) : item?.message?.meta.spam ? (
                    <div className="nk-ibx-item-elem nk-ibx-item-user">
                      <div className="user-card">
                        <UserAvatar
                          text={findUpper(
                            item?.message?.reply[item?.message?.reply.length - 1]?.firstname
                              ? item?.message?.reply[item?.message?.reply.length - 1]?.firstname +
                                  item?.message?.reply[item?.message?.reply.length - 1]?.lastname
                              : item?.message?.reply[item?.message?.reply.length - 1]?.name
                          )}
                          image={item?.message?.reply[item?.message?.reply.length - 1]?.profile_image_path}
                          theme={item?.message?.reply[item?.message?.reply.length - 1]?.theme}
                        />
                        {/* <UserAvatar text={findUpper(item?.name)} image={item?.profile_image_path} theme={item?.theme} /> */}
                        <div className="user-name ">
                          <div className={`${item?.message?.meta?.read ? "" : "lead-text"} name-text truncated-text`}>
                            {getUniqueNames(item?.message?.reply)}
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : item?.message?.meta.sent ? (
                    <div className="nk-ibx-item-elem nk-ibx-item-user">
                      <p className={`${item?.message?.meta?.read ? "" : "lead-text"} truncated-text`}>
                        <RecipientsDisplay reply={item?.message?.reply[0]?.to} />
                        {/* To:{" "}
                        {getCookie("email") === item?.message?.reply[0]?.to?.mail
                          ? "me"
                          : item?.message?.reply[0]?.to?.name} */}
                      </p>
                    </div>
                  ) : (
                    <div className="nk-ibx-item-elem nk-ibx-item-user">
                      <div className="user-card">
                        <UserAvatar
                          text={findUpper(
                            item?.message?.reply[item?.message?.reply.length - 1]?.firstname ||
                              item?.message?.reply[item?.message?.reply.length - 1]?.name
                          )}
                          image={item?.message?.reply[item?.message?.reply.length - 1]?.profile_image_path}
                          theme={item?.message?.reply[item?.message?.reply.length - 1]?.theme}
                        />
                        {/* <UserAvatar text={findUpper(item?.name)} image={item?.profile_image_path} theme={item?.theme} /> */}
                        <div className="user-name ">
                          <div className={`${item?.message?.meta?.read ? "" : "lead-text"} name-text truncated-text`}>
                            {getUniqueNames(item?.message?.reply)}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  <div className="nk-ibx-item-elem nk-ibx-item-fluid">
                    <div className="nk-ibx-context-group">
                      {item?.message?.meta?.tags?.length > 0 && (
                        <div className="nk-ibx-context-badges">
                          {item?.message?.meta?.tags.map((tag, index) => (
                            <Badge key={index} className="me-1" color={tag?.theme}>
                              {tag?.labels}
                            </Badge>
                          ))}
                        </div>
                      )}
                      <div className="nk-ibx-context">
                        <span className="nk-ibx-context-text">
                          <span className={item?.message?.meta?.read ? "" : "heading d-inline"}>
                            {item?.message?.subject ? item?.message?.subject : "(no subject)"}
                          </span>
                          <div className="d-inline-block">
                            <span className="mx-1">-</span>
                            <span className="subject-content">
                              {item?.message?.reply?.length > 0
                                ? stripHtmlTags(item?.message?.reply[item?.message?.reply.length - 1]?.replyMessage)
                                : ""}
                              {/* {item?.message?.reply?.length > 0
                                ? stripHtmlTags(
                                    item.message.reply
                                      .filter((replyItem) => {
                                        if (isOnTrashPage) {
                                          // Show only when isRecipient is true and isDeleted is true on trash path
                                          return replyItem.isRecipient && replyItem.isDelete && !replyItem.isTrash;
                                        } else {
                                          // For non-trash paths, show only when isRecipient is true and isDeleted is false
                                          return replyItem.isRecipient && !replyItem.isDelete;
                                        }
                                      })
                                      .slice() // Create a copy of the array to avoid mutating the original
                                      .reverse() // Reverse to get the last eligible reply
                                      .find(() => true)?.replyMessage // Find the last eligible reply
                                  )
                                : ""} */}
                            </span>
                          </div>
                        </span>
                      </div>
                    </div>
                  </div>
                  {item.message.reply[item.message.reply.length - 1].attachment?.length > 0 && (
                    <div className="nk-ibx-item-elem nk-ibx-item-attach">
                      <a className="link link-light" href="#link" onClick={(ev) => ev.preventDefault()}>
                        <span className="header-body-img">
                          <img src={attach} alt="path" height={18} />
                        </span>
                      </a>
                    </div>
                  )}

                  <div className="nk-ibx-item-elem nk-ibx-item-time mx-0">
                    {/* <div className="sub-text">{item.message.reply[item.message.reply.length - 1].time}</div> */}
                    <div className="sub-text">
                      {formatDateTime(item.message.reply[item.message.reply.length - 1].utctime)}
                    </div>
                  </div>

                  <div className="nk-ibx-item-elem nk-ibx-item-tools">
                    <div className="ibx-actions  menu-btn">
                      <ul className="ibx-actions-hidden gx-0">
                        {!isOnTrashPage && !isOnSpamPage && (
                          <li
                            onClick={(ev) => {
                              ev.preventDefault();
                              onArchiveClick(item.id);
                            }}
                          >
                            <TooltipComponent
                              tag="a"
                              containerClassName="btn btn-sm btn-icon btn-trigger"
                              // icon="archived"
                              path={item?.message?.meta.archived ? unarchive : archive}
                              direction="top"
                              id={`archive${item.id}Tooltip`}
                              text={`${item?.message?.meta.archived ? "Unarchive" : "Archive"}`}
                            ></TooltipComponent>
                          </li>
                        )}
                        <li
                          onClick={(ev) => {
                            ev.preventDefault();
                            seMarkAsRead(item.id);
                          }}
                        >
                          <TooltipComponent
                            tag="a"
                            containerClassName="btn btn-sm btn-icon btn-trigger"
                            // icon="trash"
                            path={item?.message?.meta.read ? MarkAsUnRead : MarkAsRead}
                            direction="top"
                            id={`delete${item.id}Tooltip`}
                            text={`${item?.message?.meta.read ? "Mark as unread" : "Mark as read"}`}
                          ></TooltipComponent>
                        </li>
                        {isOnTrashPage && (
                          <li
                            onClick={(ev) => {
                              ev.preventDefault();
                              onRestoreClick(item.id);
                            }}
                          >
                            <TooltipComponent
                              tag="a"
                              containerClassName="btn btn-sm btn-icon btn-trigger"
                              // icon="archived"
                              path={restore}
                              direction="top"
                              id={`restore${item.id}Tooltip`}
                              text={`Restore`}
                            ></TooltipComponent>
                          </li>
                        )}
                        {isOnSpamPage && (
                          <li
                            onClick={(ev) => {
                              ev.preventDefault();
                              onSpamClick(item.id);
                            }}
                          >
                            <TooltipComponent
                              tag="a"
                              containerClassName="btn btn-sm btn-icon btn-trigger"
                              // icon="archived"
                              path={notSpam}
                              direction="top"
                              id={`restore${item.id}Tooltip`}
                              text={`Not Spam`}
                            ></TooltipComponent>
                          </li>
                        )}
                        <li
                          onClick={(ev) => {
                            ev.preventDefault();
                            onDeleteClick(item.id);
                          }}
                        >
                          <TooltipComponent
                            tag="a"
                            containerClassName="btn btn-sm btn-icon btn-trigger"
                            // icon="archived"
                            path={trashBody}
                            direction="top"
                            id={`trash${item.id}Tooltip`}
                            text={isOnTrashPage || isOnSpamPage ? "Delete Forever" : "Delete"}
                          ></TooltipComponent>
                        </li>
                      </ul>
                      <ul className="ibx-actions-visible gx-2 d-none">
                        <li>
                          <UncontrolledDropdown>
                            <DropdownToggle
                              tag={"a"}
                              href="#item"
                              //   onClick={(ev) => ev.preventDefault()}
                              className="dropdown-toggle btn btn-sm btn-icon btn-trigger"
                            >
                              <span className="header-body-img">
                                <img src={arrow_down} alt="search" height={4} />
                              </span>
                            </DropdownToggle>
                            <DropdownMenu end>
                              <ul className="link-list-opt no-bdr">
                                <li>
                                  <DropdownItem
                                    tag="a"
                                    href="#item"
                                    onClick={(ev) => {
                                      ev.preventDefault();
                                      if (item?.message?.meta.draft) {
                                        openDraftCompose(item);
                                      } else onListClick(item);
                                    }}
                                  >
                                    <span className="header-body-img">
                                      <img src={view} alt="view" />
                                    </span>
                                    <span>View</span>
                                  </DropdownItem>
                                </li>
                                <li>
                                  <DropdownItem
                                    tag="a"
                                    href="#item"
                                    onClick={(ev) => {
                                      ev.preventDefault();
                                      onDeleteClick(item.id);
                                    }}
                                  >
                                    <span className="header-body-img">
                                      <img src={trash} alt="trash" height={16} />
                                    </span>
                                    <span>{isOnTrashPage || isOnSpamPage ? "Delete Forever" : "Delete"}</span>
                                  </DropdownItem>
                                </li>
                                {isOnTrashPage ? (
                                  <li>
                                    <DropdownItem
                                      tag="a"
                                      href="#item"
                                      onClick={(ev) => {
                                        ev.preventDefault();
                                        onRestoreClick(item.id);
                                      }}
                                    >
                                      <span className="header-body-img">
                                        <img src={restore} alt={"undo"} height={16} />
                                      </span>
                                      <span>Restore</span>
                                    </DropdownItem>
                                  </li>
                                ) : isOnSpamPage ? (
                                  <li>
                                    <DropdownItem
                                      tag="a"
                                      href="#item"
                                      onClick={(ev) => {
                                        ev.preventDefault();
                                        onSpamClick(item.id);
                                      }}
                                    >
                                      <span className="header-body-img">
                                        <img src={notSpam} alt={"undo"} height={16} />
                                      </span>
                                      <span>Not Spam</span>
                                    </DropdownItem>
                                  </li>
                                ) : (
                                  <li>
                                    <DropdownItem
                                      tag="a"
                                      href="#item"
                                      onClick={(ev) => {
                                        ev.preventDefault();
                                        onArchiveClick(item.id);
                                      }}
                                    >
                                      {item?.message?.meta.archived ? (
                                        <>
                                          <span className="header-body-img">
                                            <img src={unarchive} alt="archive" height={16} />
                                          </span>
                                          <span>Unrchive</span>
                                        </>
                                      ) : (
                                        <>
                                          <span className="header-body-img">
                                            <img src={archive} alt="archive" height={16} />
                                          </span>
                                          <span>Archive</span>
                                        </>
                                      )}
                                    </DropdownItem>
                                  </li>
                                )}
                              </ul>
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              )
          )
        ) : (
          <p className="text-center p-3">{emptyMessage}</p>
        )}
      </div>
    </React.Fragment>
  );
};

export default ContentBody;

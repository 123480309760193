import React, { useContext, useEffect, useState } from "react";
import { Icon, TooltipComponent } from "../../../components/Component";
import { UncontrolledDropdown, DropdownMenu, DropdownToggle, DropdownItem, UncontrolledTooltip } from "reactstrap";
import archive from "../../../images/applogos/images/archive.png";
import unarchive from "../../../images/applogos/images/unarchive.png";
import trash from "../../../images/applogos/images/trash.png";
import spam from "../../../images/applogos/images/spam.png";
import menu from "../../../images/applogos/images/menu.png";
import search from "../../../images/applogos/images/search.png";
import prev from "../../../images/applogos/images/prev.png";
import next from "../../../images/applogos/images/next.png";
import tag from "../../../images/applogos/images/tag.png";
import move from "../../../images/applogos/images/move.png";
import reply from "../../../images/applogos/images/reply.png";
import restore from "../../../images/applogos/images/restore.png";
import notSpam from "../../../images/applogos/images/back.png";
import { postRequest, postRequestOld, useDebounce } from "../../../utils/api_helper";
import { MyContext } from "../../ContextMail";

const InboxMessageHeader = ({
  msgId,
  setMailId,
  // onSpamClick,
  toggleMessageView,
  // deleteInbox,
  // onArchiveClick,
  localLabel,
  outerLabels,
  mailData,
  setMailData,
  data,
  paginationData,
  setCurrentPage,
  setPageChange,
  isTagChanged,
  setIsTagChanged,
}) => {
  const [isRestored, setIsRestored] = useState(false);
  const isTrashPath = window.location.pathname.includes("/trash") && !isRestored;
  const isOnTrash = window.location.pathname.includes("/trash");
  const isOnSpam = window.location.pathname.includes("/spam");
  const isOnAll = window.location.pathname.includes("/all-mails");

  const [searchText, setSearchText] = useState("");
  const [searchToggle, setSearchToggle] = useState(false);
  const [checkedLabel, setCheckedLabel] = useState([]);

  const { setRecentDelete, fetchTabData, setRefresh } = useContext(MyContext);

  const onSearchChange = (e) => {
    let value = e.target.value;
    setSearchText(value);
  };

  const toggleSearch = () => {
    setSearchToggle(!searchToggle);
  };

  const totalEmails = Number(paginationData?.total_records || 0);
  const perPage = Number(paginationData?.per_page) || 0;
  const currentPage = Number(paginationData?.current_page || 1);
  const lastPage = Number(paginationData?.last_page || 1);

  if (!data) {
    setMailData(null);
    data = [];
  }

  // Find the current email's index in the data array
  const currentIndex = data.findIndex((email) => email.id === msgId);

  // Determine the previous and next emails
  const prevEmail = currentIndex > 0 ? data[currentIndex - 1] : null;
  const nextEmail = currentIndex < data.length - 1 ? data[currentIndex + 1] : null;

  // Handle 'Prev' button click
  const handlePrev = () => {
    if (prevEmail) {
      // If there is a previous email, open it
      setMailId(prevEmail.id);
    } else if (currentPage > 1) {
      // If it's the first email on the current page, load the previous page
      setCurrentPage(currentPage - 1);
      setPageChange("prev");
    }
  };

  // Handle 'Next' button click
  const handleNext = () => {
    if (nextEmail) {
      // If there is a next email, open it
      setMailId(nextEmail.id);
    } else if (currentPage < lastPage) {
      // If it's the last email on the current page, load the next page
      setCurrentPage(currentPage + 1);
      setPageChange("next");
    }
  };

  const nextMessage = () => {
    if (msgId === 12) {
      setMailId(1);
    } else {
      setMailId(msgId + 1);
    }
  };

  const prevMessage = () => {
    if (msgId !== 1) {
      setMailId(msgId - 1);
    } else {
      setMailId(12);
    }
  };

  // const changeTagsOnCheck = (checked, label) => {
  //   let defaultData = mailData;
  //   let defaultTags = mailData.message.meta.tags;
  //   if (checked) {
  //     defaultTags.push(label);
  //   } else {
  //     defaultTags = defaultTags.filter((item) => item.labels !== label.labels);
  //     mailData.message.meta.tags = defaultTags;
  //   }
  //   setMailData({ ...defaultData });
  // };

  const changeTagsOnCheck = async (checked, label) => {
    let defaultData = mailData;
    let defaultTags = mailData.message.meta.tags;
    if (checked) {
      defaultTags.push(label);
    } else {
      defaultTags = defaultTags.filter((item) => item.id !== label.id);
      mailData.message.meta.tags = defaultTags;
    }
    setMailData({ ...defaultData });
    setIsTagChanged(true);
  };

  useEffect(() => {
    let defaultTags = mailData.message.meta.tags;
    setCheckedLabel(defaultTags.map((item) => item.id));
  }, [mailData]);

  const debouncedlabels = useDebounce(checkedLabel, 500);

  useEffect(() => {
    if (isTagChanged && mailData) {
      try {
        const Data = new FormData();
        Data.append("email_id", mailData?.id);
        Data.append("label_id", debouncedlabels);
        const response = postRequestOld("/mail/update-email-label", Data);
        setIsTagChanged(false); // Reset the flag after API call
      } catch (error) {
        console.error("Error updating email labels:", error);
      }
    }
  }, [debouncedlabels]);

  const onArchiveClick = async (id) => {
    let defaultData = mailData;
    defaultData.message.meta.archived = !defaultData.message.meta.archived;
    setMailData({ ...defaultData });
    if (!isOnAll) toggleMessageView();
    try {
      const data = new FormData();
      data.append("archieve_id", id);
      const response = await postRequest("/mail/change-archive-status", data);
      if (response?.status) {
        if (!isOnAll) setRecentDelete(id);
      }
    } catch (error) {
      console.log("error message", error);
    }
  };

  const onDeleteClick = async (id) => {
    let defaultData = mailData;
    defaultData.message.meta.trash = !defaultData.message.meta.trash;
    setMailData({ ...defaultData });
    toggleMessageView();
    // if (!window.location.pathname.includes("/trash")) toggleMessageView();
    try {
      const data = new FormData();
      data.append("email_id", id);
      const response = await postRequest("/mail/delete-email", data);
      if (response?.status) {
        setRecentDelete(id);
        fetchTabData();
        // setTimeout(() => {
        // }, 500);
      }
    } catch (error) {
      console.log("error message", error);
    }
  };

  const onSpamClick = async (id) => {
    let defaultData = mailData;
    defaultData.message.meta.spam = !defaultData.message.meta.spam;
    setMailData({ ...defaultData });
    toggleMessageView();
    try {
      const data = new FormData();
      data.append("email_id", id);
      const response = await postRequest("/mail/undo-spam-email", data);
      if (response?.status) {
        setRecentDelete(id);
        fetchTabData();
      }
    } catch (error) {
      console.log("error message", error);
    }
  };

  const onRestoreClick = async (id) => {
    let defaultData = mailData;
    defaultData.message.meta.trash = false;
    defaultData.message.meta.archived = false;
    setMailData({ ...defaultData });
    setIsRestored(true);
    toggleMessageView();
    try {
      const data = new FormData();
      data.append("email_id", id);
      const response = await postRequest("/mail/undo-trash-email", data);
      if (response?.status) {
        setRecentDelete(id);
        fetchTabData();
      }
    } catch (error) {
      defaultData.message.meta.trash = !defaultData.message.meta.trash;
      setMailData({ ...defaultData });
      console.log("error message", error);
    }
  };

  return (
    <div className="nk-ibx-head">
      <div className="nk-ibx-head-actions">
        <ul className="nk-ibx-head-tools g-1">
          {/* <li className="ms-n2"> */}
          <li className="ms-n2" onClick={() => toggleMessageView()}>
            <a
              href="#item"
              onClick={(ev) => {
                ev.preventDefault();
              }}
              className="btn btn-icon btn-trigger nk-ibx-hide"
            >
              <Icon name="arrow-left"></Icon>
            </a>
          </li>
          {/* <li> */}
          {isOnSpam || isOnTrash ? null : (
            <li onClick={() => onArchiveClick(msgId)}>
              {/* {console.log(mailData.message.meta.archived, mailData)} */}
              {mailData.message.meta.archived ? (
                <TooltipComponent
                  tag="a"
                  containerClassName="btn btn-icon btn-trigger"
                  // icon="archived"
                  path={unarchive}
                  id="ibx-msg-archive"
                  direction="top"
                  text="Unarchive"
                />
              ) : (
                <TooltipComponent
                  tag="a"
                  containerClassName="btn btn-icon btn-trigger"
                  // icon="archived"
                  path={archive}
                  id="ibx-msg-archive"
                  direction="top"
                  text="Archive"
                />
              )}
            </li>
          )}

          {mailData.message.meta.trash || isTrashPath ? (
            <li onClick={() => onRestoreClick(msgId)}>
              <TooltipComponent
                tag="a"
                containerClassName="btn btn-icon btn-trigger"
                // icon="report"
                path={restore}
                id="ibx-msg-spam"
                direction="top"
                text="Restore"
              />
            </li>
          ) : mailData.message.meta.spam ? (
            <li onClick={() => onSpamClick(msgId)}>
              <TooltipComponent
                tag="a"
                containerClassName="btn btn-icon btn-trigger"
                // icon="report"
                path={notSpam}
                id="ibx-msg-spam"
                direction="top"
                text="Not Spam"
              />
            </li>
          ) : (
            <li onClick={() => onSpamClick(msgId)}>
              <TooltipComponent
                tag="a"
                containerClassName="btn btn-icon btn-trigger"
                // icon="report"
                path={spam}
                id="ibx-msg-spam"
                direction="top"
                text="Report Spam"
              />
            </li>
          )}

          {/* <li> */}
          <li onClick={() => onDeleteClick(msgId)}>
            <TooltipComponent
              tag="a"
              containerClassName="btn btn-icon btn-trigger"
              path={trash}
              id="ibx-msg-trash"
              direction="top"
              text={
                mailData.message.meta.trash || isTrashPath || mailData.message.meta.spam ? "Delete Forever" : "Delete"
              }
            />
          </li>

          <li>
            <UncontrolledDropdown>
              <DropdownToggle
                tag="a"
                onClick={(ev) => ev.preventDefault()}
                className="btn btn-icon btn-trigger"
                color="transparent"
                id="dropdownToggleLabel"
              >
                <span className="header-body-img">
                  <img src={tag} alt="search" height={15} />
                </span>
                <UncontrolledTooltip autohide={false} placement="top" target="dropdownToggleLabel">
                  Label
                </UncontrolledTooltip>
              </DropdownToggle>
              <DropdownMenu>
                <ul className="link-tidy no-bdr sm">
                  {outerLabels.map((labelItem, index) => {
                    let findLabel = localLabel.find((item) => item.labels === labelItem.labels);
                    return (
                      <li key={index}>
                        <div className="custom-control custom-control-sm custom-checkbox">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            checked={findLabel ? true : false}
                            id={labelItem.id}
                            onChange={(e) => changeTagsOnCheck(e.target.checked, labelItem)}
                          />
                          <label className="custom-control-label" htmlFor={labelItem.id}>
                            <div className={`dot dot-md dot-${labelItem.theme} me-1`}></div>
                            {labelItem.labels}
                          </label>
                        </div>
                      </li>
                    );
                  })}
                </ul>
              </DropdownMenu>
            </UncontrolledDropdown>
          </li>
          {/* <li>
            <UncontrolledDropdown>
              <DropdownToggle
                tag="a"
                href="#toggle"
                onClick={(ev) => ev.preventDefault()}
                className="dropdown-toggle btn btn-icon btn-trigger"
              >
                <span className="header-body-img">
                  <img src={menu} alt="menu" height={18} />
                </span>
              </DropdownToggle>
              <DropdownMenu>
                <ul className="link-list-opt no-bdr">
                  <li>
                    <DropdownItem tag="a" href="#item" onClick={(ev) => ev.preventDefault()}>
                      <span className="header-body-img">
                        <img src={move} alt="trash" height={16} />
                      </span>
                      <span>Move to</span>
                    </DropdownItem>
                  </li>
                  <li>
                    <DropdownItem tag="a" href="#item" onClick={(ev) => ev.preventDefault()}>
                      <span className="header-body-img">
                        <img src={trash} alt="trash" height={16} />
                      </span>
                      <span>Delete</span>
                    </DropdownItem>
                  </li>
                  <li>
                    <DropdownItem tag="a" href="#item" onClick={(ev) => ev.preventDefault()}>
                      <span className="header-body-img">
                        <img src={archive} alt="archive" height={16} />
                      </span>
                      <span>Archive</span>
                    </DropdownItem>
                  </li>
                </ul>
              </DropdownMenu>
            </UncontrolledDropdown>
          </li> */}
        </ul>
      </div>
      <div>
        <ul className="nk-ibx-head-tools g-1">
          <li>
            <p className="mt-1">
              {(currentPage - 1) * perPage + currentIndex + 1} of {totalEmails}
            </p>
          </li>
          <li className="d-none d-sm-block" onClick={() => handlePrev()}>
            <TooltipComponent
              tag="a"
              containerClassName="btn btn-icon btn-trigger btn-tooltip"
              path={prev}
              id="ibx-msg-prev"
              direction="top"
              text="Prev"
            />
          </li>
          <li className="d-none d-sm-block" onClick={() => handleNext()}>
            <TooltipComponent
              tag="a"
              containerClassName="btn btn-icon btn-trigger btn-tooltip"
              path={next}
              id="ibx-msg-next"
              direction="top"
              text="Next"
            />
          </li>
          {/* <li>
            <a
              href="#item"
              onClick={(ev) => {
                ev.preventDefault();
                toggleSearch();
              }}
              className="btn btn-trigger btn-icon search-toggle toggle-search"
            >
              <span className="header-body-img">
                <img src={search} alt="search" height={18} />
              </span>
            </a>
          </li> */}
        </ul>
      </div>
      {/* <div className={`search-wrap ${searchToggle ? "active" : ""}`}>
        <div className="search-content">
          <a
            href="#item"
            onClick={(ev) => {
              ev.preventDefault();
              toggleSearch();
            }}
            className="search-back btn btn-icon toggle-search"
          >
            <span className="header-body-img">
              <img src={search} alt="search" height={18} />
            </span>
          </a>
          <input
            type="text"
            className="form-control border-transparent form-focus-none"
            placeholder="Search by user or message"
            //onChange={(e) => onSearchChange(e)}
          />
          <button className="search-submit btn btn-icon">
            <span className="header-body-img">
              <img src={search} alt="search" height={18} />
            </span>
          </button>
        </div>
      </div> */}
    </div>
  );
};

export default InboxMessageHeader;

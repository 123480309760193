import React, { useLayoutEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import InboxLayout from "../pages/app/inbox/InboxLayout";
import InboxList from "../pages/sidebarmenu/InboxList";
import DraftList from "../pages/sidebarmenu/DraftList";
import SentList from "../pages/sidebarmenu/SentList";
import SpamList from "../pages/sidebarmenu/SpamList";
import TrashList from "../pages/sidebarmenu/TrashList";
import ArchiveList from "../pages/sidebarmenu/ArchiveList";
import AllMailList from "../pages/sidebarmenu/AllMailList";
import InboxMessages from "../pages/app/inbox/InboxMessages";
import Layout from "../layout/Index";
import StarredList from "../pages/sidebarmenu/StarredList";
import Labels from "../pages/sidebarmenu/Labels";
import LabelsList from "../pages/sidebarmenu/LabelsList";

const Pages = () => {
  const location = useLocation();
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route path="" element={<InboxLayout />}>
          <Route path="" element={<InboxList />} />
          <Route path="draft" element={<DraftList />} />
          <Route path="starred" element={<StarredList />} />
          <Route path="sent" element={<SentList />} />
          <Route path="spam" element={<SpamList />} />
          <Route path="trash" element={<TrashList />} />
          <Route path="labels/:name/:id" element={<LabelsList />} />
          {/* <Route path="archive" element={<ArchiveList />} /> */}
          <Route path="all-mails" element={<AllMailList />} />
        </Route>
      </Route>
    </Routes>
  );
};
export default Pages;

import { useState, useEffect } from "react";
import { getCookie } from "../../utils/Utils";

export function calculateFilledPercentage(planValue, planType, usedValue, usedType) {
  const conversion = {
    KB: 1,
    MB: 1024,
    GB: 1024 * 1024,
    TB: 1024 * 1024 * 1024,
  };

  // Convert sizes to bytes
  const planBytes = planValue * conversion[planType];
  const usedBytes = usedValue * conversion[usedType];

  // Calculate the filled percentage
  const percentage = (usedBytes / planBytes) * 100;

  return percentage;
}

const useStorageUsage = (apiUrl) => {
  const [storageDetails, setStorageDetails] = useState({
    planStorageText: "",
    usedStorageText: "",
    percentageUsed: 0,
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchStorageDetails = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}api/v1/storage/get-user-plan`, {
        method: "GET",
        headers: {
          authToken: getCookie("authToken", null),
        },
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      // console.log(data);
      if (data && data.data) {
        const { storagePlan, usedStorage } = data.data;

        const planStorageText = storagePlan.features.storage.text;
        const usedStorageText = usedStorage.text;

        const planStorageValue = storagePlan.features.storage.value;
        const planStorageUnit = storagePlan.features.storage.unit;
        const usedStorageValue = usedStorage.value;
        const usedStorageUnit = usedStorage.unit;

        const planStorageInMB = planStorageUnit === "GB" ? planStorageValue * 1024 : planStorageValue;
        const usedStorageInMB = usedStorageUnit === "GB" ? usedStorageValue * 1024 : usedStorageValue;
        // console.log(planStorageValue, planStorageUnit, usedStorageValue, usedStorageUnit);
        const percentageUsed = calculateFilledPercentage(
          planStorageValue,
          planStorageUnit,
          usedStorageValue,
          usedStorageUnit
        );

        setStorageDetails({
          planStorageText,
          usedStorageText,
          percentageUsed,
        });
      }
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchStorageDetails();
  }, [apiUrl]);

  return { storageDetails, loading, error, refetch: fetchStorageDetails };
};

export default useStorageUsage;

import React, { useContext, useEffect, useState } from "react";
import InboxBodyHead from "../app/inbox/InboxBodyHead";
import ContentBody from "./ContentBody";
import { MyContext } from "../ContextMail";
import { postRequest, useDebounce } from "../../utils/api_helper";
import InboxMessages from "../app/inbox/InboxMessages";
import { GloabalContext } from "../../layout/Index";

const SpamList = () => {
  const limit = 30;
  const { query } = useContext(GloabalContext);
  const [currentPage, setCurrentPage] = useState(1);
  const [paginationData, setPaginationData] = useState(null);
  const [data, setData] = useState(null);
  const { messageView, refresh, setRefresh } = useContext(MyContext);
  const debouncedQuery = useDebounce(query, 400);

  const getMailList = async () => {
    const fdata = new FormData();

    if (debouncedQuery) {
      fdata.append("search", debouncedQuery);
    } else {
      fdata.append("limit", limit);
      fdata.append("page", currentPage);
    }

    try {
      const response = await postRequest("/mail/get-spam-email", fdata);
      if (response.status) {
        setData(response.data);
        setPaginationData(response.pagination);
        setRefresh(false);
      }
    } catch (error) {
      console.log("error message", error);
    }
  };

  useEffect(() => {
    getMailList();
  }, [debouncedQuery, currentPage, refresh]);

  useEffect(() => {
    setData(null);
  }, [query, currentPage]);

  return (
    <>
      {messageView ? (
        <InboxMessages data={data} setCurrentPage={setCurrentPage} paginationData={paginationData} />
      ) : (
        <>
          <InboxBodyHead
            data={data}
            setData={setData}
            // query={query}
            // setQuery={setQuery}
            setCurrentPage={setCurrentPage}
            paginationData={paginationData}
          />
          <p className="p-2 bg-theme text-center mb-1">
            Messages that have been in Spam more than 30 days will be automatically deleted.
          </p>
          {debouncedQuery && (
            <p className="p-2  text-gray text-center mb-1">
              Showing results for <span className="search-result-text fw-bold">"{debouncedQuery}"</span>
            </p>
          )}
          <ContentBody
            data={data}
            setData={setData}
            emptyMessage={
              debouncedQuery ? "No results found for your search." : "No spam detected. Your inbox is safe."
            }
          />
        </>
      )}
    </>
  );
};

export default SpamList;

import React, { useContext, useEffect, useState } from "react";
import Icon from "../../../../components/icon/Icon";
import { DropdownToggle, DropdownMenu, Dropdown } from "reactstrap";
import { useTheme, useThemeUpdate } from "../../../provider/Theme";
import { postRequest } from "../../../../utils/api_helper";
import { Link, useNavigate } from "react-router-dom";
import { GloabalContext } from "../../../Index";

const formatDateTime = (dateString) => {
  const date = new Date(dateString);
  const options = {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  };
  return new Intl.DateTimeFormat("en-US", options).format(date);
};

const handleReadNotification = (id, getNotificationData) => {
  // const payload = {
  //   module: 9,
  //   notification_id: id,
  //   status: 1,
  // };

  const payload = new FormData();
  payload.append("status", 1);
  payload.append("notification_id", id);

  postRequest("/public/set-notification-status", payload).then((res) => {
    // console.log("res mark as read", res);
    if (res.status === true) {
      getNotificationData();
    }
  });
};

const NotificationItem = (props) => {
  const { icon, msgId, text, time, id, link, toggle, getNotificationData } = props;

  const navigate = useNavigate();

  const handleLinkClick = (event, id, getNotificationData) => {
    event.preventDefault(); // Prevent the redirection
    const currentDomain = window.location.hostname;
    let linkDomain;
    if (link) {
      linkDomain = new URL(link).hostname;
    }
    if (linkDomain === currentDomain) {
      event.preventDefault(); // Prevent the redirection
      // console.log("Link matches the current domain, no redirect.");
    }
    handleReadNotification(id, getNotificationData);
    navigate(`/?msg-id=` + msgId);
    toggle();
  };

  return (
    <Link to={link} onClick={(e) => handleLinkClick(e, id, getNotificationData)}>
      <div
        className="nk-notification-item"
        key={id}
        id={id}
        // onClick={() => handleReadNotification(id, getNotificationData)}
      >
        <div className="nk-notification-icon">
          {/* <Icon
            name={link === "/portfolio" ? "invest" : "file-docs"}
            className={[`icon-circle ${iconStyle ? " " + iconStyle : ""}`]}
          /> */}
          <img width={30} src={process.env.REACT_APP_API_URL + icon} />
        </div>
        <div className="nk-notification-content">
          <div className="nk-notification-text">{text}</div>
          <div className="nk-notification-time">{formatDateTime(time)}</div>
        </div>
      </div>
    </Link>
  );
};

const Notification = () => {
  const theme = useTheme();
  const themeUpdate = useThemeUpdate();
  const [open, setOpen] = useState(false);
  const [data, setData] = useState([]);
  const [offset, setOffset] = useState(0);

  const { gotNotification, readNotification } = useContext(GloabalContext);

  const toggle = () => {
    themeUpdate.sidebarHide();
    setOpen((prevState) => !prevState);
  };

  const getNotificationData = () => {
    // const payload = { limit: 10, offset: 0, module: 9 };
    const payload = new FormData();
    payload.append("module", process.env.REACT_APP_MODULE_NAME);

    postRequest("/public/get-notifications", payload)
      .then((res) => {
        // console.log("res notification", res?.data?.notifications);
        if (res.status) setData(res?.data?.notifications);
        else setData([]);
      })
      .catch((e) => {
        setData([]);
      });
  };

  const handleViewAll = () => {
    setOffset((prevOffset) => prevOffset + 10);
    const payload = { limit: 10, offset: offset + 10, module: 9, recent: 10 };

    postRequest("/public/get-notifications", payload).then((res) => {
      // console.log("res notification", res?.data?.notifications);
      res.status === false ? setData([]) : setData((prevData) => [...prevData, ...res?.data?.notifications]);
    });
  };

  const handleMarkAllAsRead = () => {
    if (data?.length === 0) return;
    const payload = new FormData();
    payload.append("status", 1);
    payload.append("notification_id", 0);
    postRequest("/public/set-notification-status", payload).then((res) => {
      if (res.status === true) {
        setData([]);
      }
    });
  };

  useEffect(() => {
    getNotificationData();
  }, [gotNotification]);

  useEffect(() => {
    if (readNotification && readNotification.length > 0) {
      // Loop through each reference_id in the readNotification array
      readNotification.forEach((referenceId) => {
        // Find the notification object whose reference_id matches the current referenceId
        const matchingNotification = data.find((notification) => notification.reference_id == referenceId);

        // If a matching notification is found, call handleReadNotification with its id
        if (matchingNotification) {
          handleReadNotification(matchingNotification.id, getNotificationData);
        }
      });
    }
  }, [readNotification, data]);

  return (
    <Dropdown isOpen={open} className="" toggle={toggle}>
      <DropdownToggle tag="a" className="dropdown-toggle nk-quick-nav-icon">
        <div className="icon-status icon-status-na">
          <Icon name="bell-fill" className="" />
          {data?.length !== 0 && <div className="countIcon">{data.length}</div>}
        </div>
      </DropdownToggle>
      <DropdownMenu end className="dropdown-menu-xl dropdown-menu-s1">
        <div className="dropdown-head">
          <span className="sub-title nk-dropdown-title">Notifications</span>
          <p style={{ cursor: "pointer" }} onClick={() => handleMarkAllAsRead()}>
            Mark All As Read
          </p>
        </div>
        <div className="dropdown-body">
          <div className="nk-notification ">
            {data?.length === 0 && (
              <div className="nk-notification-item justify-content-center">
                <span style={{ color: "rgb(225, 73, 84)" }}>No new notifications</span>
              </div>
            )}
            {data?.map((item) => (
              <NotificationItem
                key={item.id}
                id={item.id}
                icon={item.imgSrc}
                iconStyle={item.iconStyle}
                text={item.title}
                time={item.updated_at}
                link={item.link}
                msgId={item.reference_id}
                toggle={toggle}
                getNotificationData={getNotificationData}
              />
            ))}
          </div>
        </div>
        {/* <div className="dropdown-foot center">
          <p style={{ cursor: "pointer" }} onClick={() => handleViewAll()}>
            {data?.length > 10 ? "View All" : ""}
          </p>
        </div> */}
      </DropdownMenu>
    </Dropdown>
  );
};

export default Notification;

import axios from "axios";
import { getCookie } from "./Utils";
import { useState, useEffect } from "react";

const BASE_URL = `${process.env.REACT_APP_API_URL}api/v1`; // Replace with your API base URL

const token = getCookie("authToken");

// Example of a GET request
export const getRequest = async (route) => {
  try {
    let config = {
      method: "get",
      url: `${BASE_URL}${route}`,
      //   headers: {
      //     "Content-Type": "application/json",
      //   },
      headers: {
        authToken: token,
      },
    };

    if (localStorage.getItem("token")) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    const response = await axios(config);
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};

export const fetchAPI = async (formData, route) => {
  let res = await fetch(`${process.env.REACT_APP_API_URL}${route}`, {
    method: "POST",
    cache: "no-store",
    body: formData,
    headers: {
      authToken: getCookie("authToken"),
    },
  });
  return await res.json();
};

export const postRequestData = async (data, route) => {
  try {
    const response = await fetch(`${BASE_URL}${route}`, {
      method: "POST",
      headers: {
        authToken: getCookie("authToken"),
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });

    const Response = await response.json();

    if (Response.type !== "success") {
      throw new Error("Failed to fetch data From api-services");
    }

    return Response;
  } catch (error) {
    return error.message;
  }
};

// Example of a POST request
export const postRequestOld = async (route, formData) => {
  let res = await fetch(`${BASE_URL}${route}`, {
    method: "POST",
    cache: "no-store",
    body: formData,
    headers: {
      authToken: getCookie("authToken"),
    },
  });
  return await res.json();
};

export const postRequest = async (route, formData) => {
  try {
    let res = await fetch(`${BASE_URL}${route}`, {
      method: "POST",
      cache: "no-store",
      body: formData,
      headers: {
        authToken: getCookie("authToken"),
      },
    });

    // Check if response status is not OK (i.e., not in the range 200-299)
    if (!res.ok) {
      // Create a custom error to throw
      throw new Error(`HTTP error! Status: ${res.status}`);
    }

    const result = await res.json();

    // Check if the response indicates a failure
    if (!result.status) {
      // Throw an error with the message from the response
      throw new Error(result.message || "An error occurred");
    }

    return result;
  } catch (error) {
    // Rethrow the error so it can be caught in toast.promise
    throw error;
  }
};

export const getPostRequest = async (route) => {
  let res = await fetch(`${BASE_URL}${route}`, {
    method: "POST",
    cache: "no-store",
    headers: {
      authToken: getCookie("authToken"),
    },
  });
  return await res.json();
};

// Example of a PUT request
// export const putRequest = async (route, data) => {
//   try {
//     let config = {
//       method: "put",
//       url: `${BASE_URL}${route}`,
//       //   headers: {
//       //     "Content-Type": "application/json",
//       //   },
//       headers: {
//         authToken: token,
//       },
//       data: JSON.stringify(data),
//     };

//     if (localStorage.getItem("token")) {
//       console.log("token", token);
//       config.headers.Authorization = `Bearer ${token}`;
//     }

//     const response = await axios(config);
//     return response.data;
//   } catch (error) {
//     console.error("Error putting data:", error);
//     throw error;
//   }
// };

// Example of a DELETE request
export const deleteRequest = async (route) => {
  try {
    let config = {
      method: "delete",
      url: `${BASE_URL}${route}`,
      //   headers: {
      //     "Content-Type": "application/json",
      //   },
      headers: {
        authToken: token,
      },
    };

    if (localStorage.getItem("token")) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    const response = await axios(config);
    return response.data;
  } catch (error) {
    console.error("Error deleting data:", error);
    throw error;
  }
};

// // api-service.js

// export const fetchCoins = async (payload) => {
//   const { data } = await axios.post('https://api.silocloud.io/recently-added', payload);
//   return data;
// };

// export default api;

export const postRequestKYC = async (route, data) => {
  try {
    let config = {
      method: "post",
      url: `https://api.silocloud.io/api/v1/${route}`,
      headers: {
        authToken: token,
      },
      //   data: JSON.stringify(data),
      data: data,
    };

    if (localStorage.getItem("token")) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    const response = await axios(config);
    return response.data;
  } catch (error) {
    console.error("Error posting data:", error);
    throw { message: "Something went wrong" };
  }
};

export const fetchCloudSearchApi = async (route) => {
  let response = await fetch(`${process.env.REACT_APP_API_URL}${route}`, {
    method: "GET",
    cache: "no-store",
    headers: {
      authToken: token,
    },
  });
  if (!response.ok) {
    const errorData = await response.json();
    const error = new Error("An Error occurred while AppDropdown.");
    error.info = errorData;
    error.code = `${response.status}`;
    throw error;
  }
  return await response.json();
};

export const fetchGet = async (route) => {
  let res = await fetch(`${BASE_URL}${route}`, {
    method: "GET",
    cache: "no-store",
    headers: {
      authToken: getCookie("authToken"),
    },
  });
  return await res.json();
};

// Custom hook to debounce a value
export const useDebounce = (value, delay) => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
};

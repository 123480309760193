import React, { createContext, useContext, useEffect, useState } from "react";
import { Link, Outlet } from "react-router-dom";
import Head from "./head/Head";
import Header from "./header/Header";
import Footer from "./footer/Footer";
import AppRoot from "./global/AppRoot";
import AppMain from "./global/AppMain";
import AppWrap from "./global/AppWrap";
import { toast, ToastContainer } from "react-toastify";
import "../pages/css/mail.css";
import Cookies from "js-cookie";
import FourteenDaysCountDown from "../pages/hooks/FourteenDaysCountDown";
// import MobileFooter from "./footer/MobileFooter";
import { Alert, Toast, ToastBody } from "reactstrap";
import { Icon } from "../components/Component";
import { io } from "socket.io-client";
import { getCookie } from "../utils/Utils";
import useStorageUsage from "../pages/hooks/useStorageUsage";
export const GloabalContext = createContext();

const Layout = ({ title, app, ...props }) => {
  const { storageDetails, loading, error, refetch } = useStorageUsage();

  const [toastVisible, setToastVisible] = useState(false);
  const [action, setAction] = useState("");
  const [undoMessage, setUndoMessage] = useState(() => {
    // console.log("abc");
  });
  const [showMessage, setShowMessage] = useState();
  const [authChecking, setAuthChecking] = useState(true);
  const [files, setFiles] = useState([]);
  const [loadingList, setLoadingList] = useState(false);
  // const { storageFiledAlert } = useContext(MyContext);

  const [aside, setAside] = useState(false);

  const [isComposeBox, setIsComposeBox] = useState(false);

  const [gotNotification, setGotNotification] = useState(0);
  const [readNotification, setReadNotification] = useState([]);

  const [query, setQuery] = useState(""); // Default to empty string for search query
  const endDate = new Date("2024-05-30 09:38:09"); // Replace with your desired end date
  const { days, hours, minutes, seconds } = FourteenDaysCountDown(endDate);
  const formattedCountdown = `${days}d ${hours}h ${minutes}m ${seconds}s`;

  const username = getCookie("username");
  const moduleName = process.env.REACT_APP_MODULE_NAME;
  const targetURL = process.env.REACT_APP_PUBLIC_URL;
  const socket = io(process.env.REACT_APP_SOCKET_URL);

  useEffect(() => {
    const notifyWithToast = (data) => {
      toast.info(
        <>
          <strong>{data.title}</strong>
          <br />
          {data.body}
        </>,
        {
          closeOnClick: true,
          autoClose: true, // Toast will only close on button click
          position: "top-right",
        }
      );
    };

    socket.on(`notification_${moduleName}_${username}`, (data) => {
      // console.log("Notification received:", data);
      console.log(data);
      if (data) {
        setGotNotification((prev) => prev + 1);

        notifyWithToast(data);

        if ("Notification" in window) {
          if (Notification.permission === "granted") {
            const notification = new Notification(`Notification: ${data.title}`, {
              body: `${data.body}`,
              icon: process.env.REACT_APP_API_URL + "logo/apps/silo-mail.png",
            });
            notification.onclick = function () {
              window.open(targetURL, "_blank");
            };
          } else if (Notification.permission !== "denied") {
            Notification.requestPermission().then((permission) => {
              if (permission === "granted") {
                const notification = new Notification(`Notification: ${data.title}`, {
                  body: `${data.body}`,
                  icon: process.env.REACT_APP_API_URL + "logo/apps/silo-mail.png",
                });
                notification.onclick = function () {
                  window.open(targetURL, "_blank");
                };
              }
            });
          }
        } else {
          console.log("Browser does not support notifications.");
        }
      }
    });

    return () => {
      socket.off(`notification_${moduleName}_${username}`);
    };
  }, []);

  useEffect(() => {
    let at = Cookies.get("authToken");
    if (!at) {
      const now = new Date();
      const expires = new Date(now.getTime() + 24 * 60 * 60 * 1000); // 24 hours in milliseconds
      Cookies.set("currentURL", window.location.href, {
        domain: process.env.REACT_APP_COOKIE_DOMAIN,
        expires: expires,
      });
      window.location = process.env.REACT_APP_ACCOUNT_URL;
    } else {
      setAuthChecking(false);
      Cookies.remove("currentURL", { domain: process.env.REACT_APP_COOKIE_DOMAIN });
    }
  }, []);
  if (authChecking) return <>Redirecting to authentication</>;

  const showToast = (message, action, undoMessage, time) => {
    setAction(action);
    setUndoMessage(() => undoMessage);
    setToastVisible(true);
    setTimeout(() => {
      setToastVisible(false);
    }, time);

    setShowMessage(message);
  };

  return (
    <>
      <GloabalContext.Provider
        value={{
          aside,
          setAside,
          files,
          setFiles,
          toastVisible,
          setToastVisible,
          showMessage,
          setShowMessage,
          loadingList,
          setLoadingList,
          undoMessage,
          showToast,
          isComposeBox,
          setIsComposeBox,
          gotNotification,
          query,
          setQuery,
          storageDetails,
          loading,
          error,
          refetch,
          readNotification,
          setReadNotification,
        }}
      >
        <Head title={!title && "Loading"} />
        <AppRoot className="npc-apps">
          <AppMain>
            {/* <Sidebar fixed /> */}
            <AppWrap>
              <Header fixed />
              {/* <ToastContainer hideProgressBar autoClose={1200} /> */}
              {/* <ToastContainer /> */}
              {/* {storageFiledAlert && (
                <Alert className="alert-icon" color="primary">
                  <Icon name="alert-circle" />
                  Your storage has been filled with 90% or above and you won't be able to send attachment's in your mail
                  please upgrade the storage.
                </Alert>
              )} */}
              {toastVisible && (
                <div
                  className="show-toaster toast-container"
                  style={{ position: "absolute", bottom: "5rem", left: "1rem" }}
                >
                  <Toast className="border-0 px-3 ">
                    <ToastBody className="d-flex justify-content-between">
                      <small>{showMessage}</small>

                      {action && (
                        <Link
                          to=""
                          className="ms-3"
                          onClick={() => {
                            undoMessage();
                          }}
                        >
                          {action}
                        </Link>
                      )}
                    </ToastBody>
                  </Toast>
                </div>
              )}
              <Outlet />
              <Footer fixed />
            </AppWrap>
          </AppMain>
        </AppRoot>
      </GloabalContext.Provider>
    </>
  );
};
export default Layout;

import { useState, useRef, useEffect } from "react";
import "../css/videoRec.css";
import { Icon } from "../../components/Component";

const VideoRecorder = ({ onVideoRecorded, toggleMessageModal, isModalOpen }) => {
  const [permission, setPermission] = useState(false);
  const mediaRecorder = useRef(null);
  const liveVideoFeed = useRef(null);
  const [recordingStatus, setRecordingStatus] = useState("inactive");
  const [stream, setStream] = useState(null);
  const [videoChunks, setVideoChunks] = useState([]);
  const [recordedVideo, setRecordedVideo] = useState(null);
  const [isCameraActive, setIsCameraActive] = useState(false);
  const [recordingTime, setRecordingTime] = useState(0);
  const [isPaused, setIsPaused] = useState(false); // Controls the pause state
  const [loadingCamera, setLoadingCamera] = useState(true);
  const mimeType = "video/webm";
  const [recordedBlob, setRecordedBlob] = useState(null); // State for storing the blob

  useEffect(() => {
    if (liveVideoFeed.current && stream) {
      liveVideoFeed.current.srcObject = stream;
      liveVideoFeed.current.play().catch((err) => console.error("Error playing video feed:", err));
    }
  }, [stream]);

  const getCameraPermission = async () => {
    setRecordedVideo(null);
    if ("MediaRecorder" in window) {
      try {
        const videoConstraints = { audio: true, video: true };
        const videoStream = await navigator.mediaDevices.getUserMedia(videoConstraints);
        setPermission(true);
        setLoadingCamera(false);
        setStream(videoStream);
        setIsCameraActive(true);
      } catch (err) {
        alert(`Error getting media: ${err.message}`);
        setLoadingCamera(false);
      }
    } else {
      alert("The MediaRecorder API is not supported in your browser.");
    }
  };

  useEffect(() => {
    getCameraPermission();
    return () => {
      stopStream(); // Stop the stream when the component unmounts
    };
  }, []);

  // Stop stream when modal closes
  useEffect(() => {
    if (!isModalOpen) {
      stopStream(); // Stop camera and mic when modal closes
    }
  }, [isModalOpen]);

  const stopStream = () => {
    if (stream) {
      stream.getTracks().forEach((track) => track.stop());
    }
    setStream(null);
    setIsCameraActive(false);
  };

  const startRecording = () => {
    setRecordingStatus("recording");
    setIsPaused(false); // Reset pause state on new recording
    const media = new MediaRecorder(stream, { mimeType });
    mediaRecorder.current = media;
    mediaRecorder.current.start();
    setRecordingTime(0);
    let localVideoChunks = [];

    // Collect chunks of video data
    mediaRecorder.current.ondataavailable = (event) => {
      if (event.data && event.data.size > 0) {
        localVideoChunks.push(event.data);
      }
    };

    // Handle stop event to create video blob
    mediaRecorder.current.onstop = () => {
      const videoBlob = new Blob(localVideoChunks, { type: mimeType });
      const videoUrl = URL.createObjectURL(videoBlob);
      setRecordedVideo(videoUrl);
      setVideoChunks(localVideoChunks);
      setRecordedBlob(videoBlob); // Set the recorded blob for later use

      // Stop the live camera feed and clear srcObject
      if (liveVideoFeed.current && liveVideoFeed.current.srcObject) {
        stopStream(); // Stop stream and clear video element source
        liveVideoFeed.current.srcObject = null;
      }

      setIsCameraActive(false); // Camera is no longer active
    };
  };

  const stopRecording = () => {
    setRecordingStatus("inactive");
    if (mediaRecorder.current && mediaRecorder.current.state !== "inactive") {
      mediaRecorder.current.stop(); // Stop the media recording
    }
    setIsPaused(false); // Reset pause state when recording stops
  };

  const togglePauseRecording = () => {
    if (isPaused) {
      mediaRecorder.current.resume();
    } else {
      mediaRecorder.current.pause();
    }
    setIsPaused(!isPaused);
  };

  const discardRecording = () => {
    setRecordedVideo(null);
    setRecordingStatus("inactive");
    stopStream(); // Stop the existing stream
    setIsPaused(false); // Reset pause state when discarding
    getCameraPermission(); // Reinitialize the camera feed
  };

  const handleRecordingComplete = () => {
    if (recordedBlob) {
      onVideoRecorded(recordedBlob);
    }

    // Close the modal or handle the state
    toggleMessageModal();
  };

  useEffect(() => {
    let timer;
    if (recordingStatus === "recording" && !isPaused) {
      timer = setInterval(() => {
        setRecordingTime((prevTime) => prevTime + 1);
      }, 1000);
    }
    return () => clearInterval(timer);
  }, [recordingStatus, isPaused]);

  const formatTime = (timeInSeconds) => {
    const minutes = Math.floor(timeInSeconds / 60);
    const seconds = timeInSeconds % 60;
    return `${String(minutes).padStart(2, "0")}:${String(seconds).padStart(2, "0")}`;
  };

  return (
    <div className="video-recorder">
      {loadingCamera ? (
        <div className="loader">Getting camera...</div>
      ) : (
        <>
          {!recordedVideo ? (
            <div className="p-5">
              <div className="video-container pulse">
                <video ref={liveVideoFeed} autoPlay muted className="video-preview-circle"></video>
                <div className="controls">
                  <div className="side-elem">
                    {recordingStatus === "recording" && (
                      <button onClick={togglePauseRecording} className="pause-resume-btn">
                        <Icon
                          name={isPaused ? "play-fill" : "pause-fill"}
                          size={24}
                          className="pause-resume-btn-icon"
                        />
                      </button>
                    )}
                  </div>

                  {recordingStatus === "inactive" && !recordedVideo ? (
                    <button onClick={startRecording} className="record-btn">
                      <div className="record-circle"></div>
                    </button>
                  ) : recordingStatus === "recording" ? (
                    <button onClick={stopRecording} className="record-btn recording">
                      <div className="record-circle"></div>
                    </button>
                  ) : null}

                  <div className="side-elem">
                    {recordingStatus === "recording" && (
                      <span className="recording-time">{formatTime(recordingTime)}</span>
                    )}
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="px-5 py-4">
              <video src={recordedVideo} controls className="recorded-video"></video>
              <div className="video-options">
                <button onClick={discardRecording} className="btn btn-primary discrad-btn">
                  Discard Video
                </button>
                <button onClick={handleRecordingComplete} className="btn btn-primary">
                  Add to Attachments
                </button>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default VideoRecorder;

import React, { useContext, useEffect, useState } from "react";
import { Icon } from "../../components/Component";
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap";
import LabelFormModal from "../app/inbox/LabelFormModal";
import { getPostRequest, postRequest, postRequestOld } from "../../utils/api_helper";
import { toast } from "react-toastify";
import { MyContext } from "../ContextMail";
import { NavLink, useParams } from "react-router-dom";

const Labels = () => {
  const { labels, setLabels, fetchTabData, setMessageView, setCheckMail } = useContext(MyContext);
  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  const [isContent, setIsContent] = useState(false);
  const openContent = () => {
    // console.log("function called..");
    setIsContent(!isContent);
  };
  const [labelModal, setLabelModal] = useState({
    edit: false,
    add: false,
  });
  const [labelFormData, setLabelFormData] = useState({
    label: "",
    theme: {
      value: "primary",
      label: "Red",
    },
  });

  const [editLabelFormData, setEditLabelFormData] = useState({
    editId: 0,
    label: "",
    theme: {
      value: "primary",
      label: "Primary",
    },
  });
  const resetLabelForm = () => {
    setLabelFormData({
      label: "",
      theme: {
        value: "primary",
        label: "Red",
      },
    });
  };

  const onLabelFormSubmit = async (form) => {
    setLoading(true);
    const { label, theme } = form;
    const Data = new FormData();
    Data.append("label", label);
    Data.append("theme", theme.value);
    const response = await postRequestOld("/mail/add-label", Data);
    if (response.status) {
      setLoading(false);
      onLabelFormCancel();
      getLabels();
    }
    setLabelModal({
      edit: false,
      add: false,
    });
  };

  const onLabelFormCancel = () => {
    setLabelModal({
      edit: false,
      add: false,
    });
    resetLabelForm();
  };

  const onLabelEditFormSubmit = (form) => {
    const { label, theme } = form;
    let dataObject = {
      id: editLabelFormData.editId,
      text: label,
      color: theme.value,
    };
    let defaultData = labels;
    let foundData = defaultData.findIndex((item) => item.id === editLabelFormData.editId);
    defaultData[foundData] = dataObject;
    setLabels([...defaultData]);
    resetEditLabelForm();
    setLabelModal({
      edit: false,
    });
  };

  const resetEditLabelForm = () => {
    setEditLabelFormData({
      editId: 0,
      label: "",
      theme: {
        value: "primary",
        label: "Primary",
      },
    });
  };

  const colourOptions = [
    { value: "primary", label: "Red" },
    { value: "success", label: "Green" },
    { value: "info", label: "Blue" },
    { value: "warning", label: "Yellow" },
  ];

  const onLabelEditClick = (id) => {
    labels.forEach((item) => {
      if (item.id === id) {
        const selected_theme = colourOptions.filter(function (option) {
          return option.value === item.color;
        });
        setLabelModal({ ...labelModal, edit: true });
        setEditLabelFormData({
          label: item.text,
          theme: {
            value: selected_theme[0].value,
            label: selected_theme[0].label,
          },
          editId: item.id,
        });
      }
    });
  };

  const deleteLabel = async (id) => {
    const formData = new FormData();
    formData.append("id", id);

    // Using the post request directly inside the toast.promise
    toast
      .promise(
        postRequest("/mail/delete-label", formData),
        {
          pending: "Deleting label...",
          success: "Label deleted successfully.",
          error: "Failed to delete the label.",
        },
        { autoClose: 3000 }
      )
      .then((response) => {
        if (response?.status) {
          getLabels();
        }
      })
      .catch((error) => {
        console.error("Error while deleting label:", error);
      });
  };

  const getLabels = async () => {
    const response = await getPostRequest("/mail/get-label");
    setLabels(response?.data || []);
  };

  useEffect(() => {
    getLabels();
  }, []);

  return (
    <>
      <div className="nk-ibx-nav-head pt-2 pb-1 px-2">
        <div
          className="d-flex align-items-center pointer py-2 px-2 w-100 justify-content-between"
          onClick={openContent}
        >
          <h6 className="title">Labels</h6>
          {isContent ? <Icon name="upword-ios"></Icon> : <Icon name="downward-ios"></Icon>}
        </div>
        <a
          className="link p-1"
          href="#add"
          onClick={(ev) => {
            ev.preventDefault();
            ev.stopPropagation();
            setIsContent(true);
            setLabelModal({ ...labelModal, add: true });
          }}
        >
          <Icon name="plus-c"></Icon>
        </a>
      </div>
      {isContent && (
        <ul className="nk-ibx-label">
          {labels?.map((item) => (
            <li
              key={item.id}
              onClick={() => {
                setMessageView(false);
                setCheckMail([]);
                fetchTabData();
              }}
            >
              <NavLink
                to={"/labels/" + item.labels + "/" + item.id}
                className={`nk-ibx-menu-item justify-content-between labels-list ${item.id === id ? "active" : ""}`}
              >
                <a
                  href="#select"
                  //   onClick={(ev) => {
                  //     ev.preventDefault();
                  //     setFilterLabel(item.text);
                  //   }}
                  className="d-flex align-items-center"
                >
                  <span className={`nk-ibx-label-dot dot dot-xl dot-label bg-${item.theme}`}></span>
                  <span className="nk-ibx-menu-text ps-2">{item.labels}</span>
                </a>
                {item?.user_id !== null && (
                  <UncontrolledDropdown>
                    <DropdownToggle
                      tag="a"
                      href="#item"
                      onClick={(ev) => {
                        ev.preventDefault();
                        deleteLabel(item.id);
                      }}
                      className="dropdown-toggle text-end p-0 h-16"
                    >
                      {/* <Icon name="more-v"></Icon> */}
                      <Icon name="trash"></Icon>
                    </DropdownToggle>
                    <DropdownMenu end className="dropdown-menu-sm d-none">
                      <ul className="link-list-opt no-bdr">
                        <li>
                          <DropdownItem
                            tag="a"
                            href="#item"
                            onClick={(ev) => {
                              ev.preventDefault();
                              onLabelEditClick(item.id);
                            }}
                          >
                            <span>Edit Label</span>
                          </DropdownItem>
                        </li>
                        <li>
                          <DropdownItem
                            tag="a"
                            href="#item"
                            onClick={(ev) => {
                              ev.preventDefault();
                              deleteLabel(item.id);
                            }}
                          >
                            <span>Remove Label</span>
                          </DropdownItem>
                        </li>
                        <li className="divider"></li>
                      </ul>
                      <ul className="link-check">
                        <li>
                          <DropdownItem tag="a" href="#item" onClick={(ev) => ev.preventDefault()}>
                            Show if unread
                          </DropdownItem>
                        </li>
                        <li className="active">
                          <DropdownItem tag="a" href="#item" onClick={(ev) => ev.preventDefault()}>
                            Show
                          </DropdownItem>
                        </li>
                        <li>
                          <DropdownItem tag="a" href="#item" onClick={(ev) => ev.preventDefault()}>
                            Hide
                          </DropdownItem>
                        </li>
                      </ul>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                )}
              </NavLink>
            </li>
          ))}
        </ul>
      )}
      <LabelFormModal
        modal={labelModal.add}
        modalAction="add"
        formData={labelFormData}
        setFormData={setLabelFormData}
        closeModal={onLabelFormCancel}
        onSubmit={onLabelFormSubmit}
        colourOptions={colourOptions}
        loadingValue={loading}
      />
      <LabelFormModal
        modal={labelModal.edit}
        modalAction="edit"
        formData={editLabelFormData}
        setFormData={setEditLabelFormData}
        closeModal={onLabelFormCancel}
        onSubmit={onLabelEditFormSubmit}
        colourOptions={colourOptions}
        loadingValue={loading}
      />
    </>
  );
};

export default Labels;

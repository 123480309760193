import React, { useEffect, useState, useRef, useContext } from "react";
import InboxMessageHeader from "./InboxMessageHeader";
import SimpleBar from "simplebar-react";
import InboxReplyItem from "./InboxReplyItem";
import { Button, Icon, TooltipComponent } from "../../../components/Component";
import { postRequest } from "../../../utils/api_helper";
import { MyContext } from "../../ContextMail";
import MessageViewShimmer from "../../MessageViewShimmer";
import ReplyForm from "./ReplyForm";
import { toast } from "react-toastify";
import { GloabalContext } from "../../../layout/Index";

const InboxMessages = ({
  // mailId,
  // data = inboxList,
  // setMailId,
  // toggleMessageView,
  // onFavoriteClick,
  // deleteInbox,
  // onArchiveClick,
  // onSpamClick,
  // outerLabels,
  data,
  paginationData,
  setCurrentPage,
}) => {
  const {
    toggleMessageView,
    selectedId: mailId,
    setSelectedId: setMailId,
    setRecentRead,
    openDraftCompose,
    fetchTabData,
    labels,
    setLabels,
  } = useContext(MyContext);

  const { setReadNotification } = useContext(GloabalContext);

  const [pageChange, setPageChange] = useState(null);

  const [mailData, setMailData] = useState(null);

  const [replyingTo, setReplyingTo] = useState("");
  const [isReplyAll, setIsReplyAll] = useState(false);

  const [isTagChanged, setIsTagChanged] = useState(false);

  useEffect(() => {
    if (data && data.length > 0) {
      if (pageChange === "prev") {
        // Page changed to previous, set to the last email ID
        setMailId(data[data.length - 1]?.id);
      } else if (pageChange === "next") {
        // Page changed to next, set to the first email ID
        setMailId(data[0]?.id);
      }
    }
  }, [data]);

  const getMail = async (id, live = false) => {
    // First check if the email exists in local data
    const localMail = getLocalMail(id);

    if (localMail && !live) {
      // If email found locally, set mail data and perform necessary actions
      setMailData(localMail);

      // Check for draft or unread status and proceed accordingly
      if (localMail.message.meta.draft) {
        toggleMessageView();
        openDraftCompose(localMail);
      } else if (!localMail.message.meta?.read) {
        setReadNotification((prevNotifications) => [...prevNotifications, id]);
        try {
          const newdata = new FormData();
          newdata.append("read_id", id);
          newdata.append("action", "read");
          const response = await postRequest("/mail/read-email", newdata);
          if (response.status) {
            fetchTabData();
            setRecentRead((prevRecentRead) => {
              if (!prevRecentRead.includes(id)) {
                return [...prevRecentRead, id];
              }
              return prevRecentRead;
            });
          }
        } catch (error) {
          console.log("error message", error);
        }
      }
      return;
    }
    // If email is not found locally, proceed with API call
    try {
      const newData = new FormData();
      newData.append("email_id", id);
      const response = await postRequest("/mail/get-email", newData);

      if (response.status) {
        setReadNotification((prevNotifications) => [...prevNotifications, id]);
        setMailData(response?.data);

        if (response.data.message.meta.draft) {
          toggleMessageView();
          openDraftCompose(response.data);
        } else if (!response?.data.message.meta?.read) {
          try {
            const newdata = new FormData();
            newdata.append("read_id", id);
            newdata.append("action", "read");
            const response = await postRequest("/mail/read-email", newdata);
            if (response.status) {
              fetchTabData();
              setRecentRead((prevRecentRead) => {
                if (!prevRecentRead.includes(id)) {
                  return [...prevRecentRead, id];
                }
                return prevRecentRead;
              });
            }
          } catch (error) {
            console.log("error message", error);
          }
        }
      } else {
        toast.error("Email not found!");
        toggleMessageView();
      }
    } catch (error) {
      console.log("error message", error);
      toast.error("Email not found!");
      toggleMessageView();
    }
  };

  const getLocalMail = (id) => {
    if (id && id != "null") {
      return data?.find((email) => email.id === id) || null;
    }
  };

  useEffect(() => {
    setMailData(null);
    getMail(mailId);
  }, [mailId]);

  useEffect(() => {
    if (mailData) {
      const isTrashPath = window.location.pathname.includes("/trash");

      // const lastRecipientReply = mailData.message.reply
      //   .filter((replyItem) => {
      //     if (isTrashPath) {
      //       // On trash path, check if isRecipient is true and isDeleted is true, and hide if isTrash is true
      //       return replyItem.isRecipient && replyItem.isDelete && !replyItem.isTrash;
      //     } else {
      //       // On non-trash paths, check if isRecipient is true, isDeleted is false, and sending is false
      //       return replyItem.isRecipient && !replyItem.isDelete && !replyItem.sending;
      //     }
      //   })
      //   .slice() // Create a copy of the array to avoid mutating the original
      //   .reverse() // Reverse the array to start from the last element
      //   .find((reply) => reply.isRecipient === true); // Find the first element with isRecipient true

      const lastRecipientReply = mailData.message.reply
        .slice() // Create a copy of the array to avoid mutating the original
        .reverse() // Reverse the array to start from the last element
        .find((reply) => reply.isRecipient === true); // Find the first element with isRecipient true

      if (lastRecipientReply) {
        setReplyingTo(lastRecipientReply.replyId);
      }
    }
  }, [mailData]);

  const removeTag = (id) => {
    let defaultData = mailData;
    let tags = defaultData.message.meta.tags.filter((item) => item.id !== id);
    defaultData.message.meta.tags = tags;
    setMailData({ ...defaultData });
    setIsTagChanged(true);
  };

  const messagesEndRef = useRef(null);

  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      const scrollHeight = messagesEndRef.current.scrollHeight;
      const height = messagesEndRef.current.clientHeight;
      const maxScrollTop = scrollHeight - height;
      messagesEndRef.current.scrollTop = maxScrollTop > 0 ? maxScrollTop : 0;
    }
  };

  const setRepMsg = (id) => {
    setReplyingTo(id);
    scrollToBottom();
  };

  const onFavoriteClick = async (id) => {
    setMailData((prev) => {
      return {
        ...prev,
        message: {
          ...prev.message,
          meta: {
            ...prev.message.meta,
            favourite: !prev.message.meta.favourite,
          },
        },
      };
    });

    try {
      const data = new FormData();
      data.append("favourite_id", id);
      const response = await postRequest("/mail/favourite-inbox-list-email", data);
      if (response?.status) {
      }
    } catch (error) {
      console.log("error message", error);
    }
  };

  const deleteMessage = (id) => {
    let defaultData = mailData;
    let defaultReply = defaultData.message.reply.filter((replyItem) => {
      const isTrashPath = window.location.pathname.includes("/trash");

      if (isTrashPath) {
        // Show only when isRecipient and isDeleted are true on the trash path
        if (replyItem.isTrash) return false; // Hide if isTrash is true
        return replyItem.isRecipient && replyItem.isDelete;
      } else {
        // For non-trash paths, show only when isRecipient is true and isDeleted is false
        return replyItem.isRecipient && !replyItem.isDelete;
      }
    });

    const data = new FormData();
    data.append("reply_id", id);
    toast
      .promise(
        postRequest("/mail/delete-reply-email", data),
        {
          pending: "Deleting message...",
          success: "Message deleted successfully.",
          error: "Failed to delete the message.",
        },
        { autoClose: 3000 }
      )
      .then((response) => {
        if (response?.status) {
          fetchTabData();
          if (defaultReply.length === 1) {
            toggleMessageView();
          }

          defaultData.message.reply = defaultData.message.reply.map((item) => {
            if (item.replyId === id) {
              return { ...item, isDelete: true, isTrash: window.location.pathname.includes("/trash") }; // Mark isDelete as true for the matching id
            }
            return item;
          });
          setMailData({ ...defaultData });
        }
      })
      .catch((error) => {
        console.error("Error while deleting reply:", error);
      });
  };

  return (
    <React.Fragment>
      {!mailData ? (
        <MessageViewShimmer />
      ) : (
        <React.Fragment>
          {/* <InboxMessageHeader
            toggleMessageView={toggleMessageView}
            deleteInbox={deleteInbox}
            onArchiveClick={onArchiveClick}
            onSpamClick={onSpamClick}
            msgId={mailData.id}
            setMailId={setMailId}
            localLabel={mailData.message.meta.tags}
            outerLabels={outerLabels}
            mailData={mailData}
            setMailData={setMailData}
          /> */}
          <InboxMessageHeader
            toggleMessageView={toggleMessageView}
            msgId={mailData.id}
            setMailId={setMailId}
            localLabel={mailData.message.meta.tags}
            outerLabels={labels}
            mailData={mailData}
            setMailData={setMailData}
            data={data}
            setCurrentPage={setCurrentPage}
            paginationData={paginationData}
            setPageChange={setPageChange}
            isTagChanged={isTagChanged}
            setIsTagChanged={setIsTagChanged}
          />
          {/* <MessageViewShimmer /> */}
          <SimpleBar className="nk-ibx-reply nk-reply" scrollableNodeProps={{ ref: messagesEndRef }}>
            <div className="nk-ibx-reply-head">
              <div>
                <h4 className="title">{mailData.message.subject ? mailData.message.subject : "(no subject)"}</h4>
                <ul className="nk-ibx-tags g-1">
                  {mailData.message.meta.tags.map((tagItem, index) => (
                    <li className="btn-group is-tags" key={index}>
                      <Button color={tagItem.theme} size="xs" className="btn-dim">
                        {tagItem.labels}
                      </Button>
                      <Button
                        color={tagItem.theme}
                        size="xs"
                        className="btn-icon btn-dim"
                        onClick={() => removeTag(tagItem.id)}
                      >
                        <Icon name="cross"></Icon>
                      </Button>
                    </li>
                  ))}
                </ul>
              </div>
              <ul className="d-flex g-1">
                {/* <li className="d-none d-sm-block">
                  <TooltipComponent
                    tag="a"
                    containerClassName="btn btn-icon btn-trigger btn-tooltip"
                    icon="printer"
                    id="ibx-msg-print"
                    direction="left"
                    text="Print"
                  />
                </li> */}
                <li className="me-n1">
                  <div className="asterisk">
                    <a
                      href="#item"
                      className={`btn btn-trigger btn-icon btn-tooltip ${
                        mailData.message.meta.favourite ? "active" : ""
                      }`}
                      onClick={(ev) => {
                        ev.preventDefault();
                        onFavoriteClick(mailData.id);
                      }}
                    >
                      <Icon name={`${mailData.message.meta.favourite ? "star-fill" : "star"}`}></Icon>
                    </a>
                  </div>
                </li>
              </ul>
            </div>
            <div className="nk-ibx-reply-group">
              {/* {mailData.message.reply
                .filter((replyItem) => {
                  const isTrashPath = window.location.pathname.includes("/trash");

                  if (isTrashPath) {
                    // Show only when isRecipient and isDeleted are true on the trash path
                    if (replyItem.isTrash) return false; // Hide if isTrash is true
                    return replyItem.isRecipient && replyItem.isDelete;
                  } else {
                    // For non-trash paths, show only when isRecipient is true and isDeleted is false
                    return replyItem.isRecipient && !replyItem.isDelete;
                  }
                })
                .map(
                  (replyItem, index) =>
                    replyItem.isRecipient && (
                      <InboxReplyItem
                        reply={replyItem}
                        key={index}
                        deleteMessage={deleteMessage}
                        // replyTo={replyTo}
                        // forwardTo={forwardTo}
                        mailData={mailData}
                        setRepMsg={setRepMsg}
                        replies={mailData.message.reply}
                        setIsReplyAll={setIsReplyAll}
                      />
                    )
                )} */}
              {mailData.message.reply.map(
                (replyItem, index) =>
                  replyItem.isRecipient && (
                    <>
                      <InboxReplyItem
                        reply={replyItem}
                        key={index}
                        deleteMessage={deleteMessage}
                        // replyTo={replyTo}
                        // forwardTo={forwardTo}
                        mailData={mailData}
                        setRepMsg={setRepMsg}
                        replies={mailData.message.reply}
                        setIsReplyAll={setIsReplyAll}
                      />
                      {/* {setReplyingTo(replyItem.replyId)} */}
                    </>
                  )
              )}
            </div>

            <ReplyForm
              mailData={mailData}
              replyingTo={replyingTo}
              setMailData={setMailData}
              messagesEndRef={messagesEndRef}
              getMail={getMail}
              isReplyAll={isReplyAll}
            />
          </SimpleBar>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default InboxMessages;

import React, { useEffect, useState, useRef, useContext } from "react";
import Tags from "@yaireo/tagify/dist/react.tagify";
import { Button, Icon, TooltipComponent } from "../../../components/Component";
import { UncontrolledDropdown, DropdownToggle, UncontrolledTooltip, Modal } from "reactstrap";
import { currentTime, formatReplyDateTime, getCookie, getDateStructured, showStorageAlert } from "../../../utils/Utils";
import image from "../../../images/applogos/images/image.png";
import attache from "../../../images/applogos/images/attach.png";
import audio from "../../../images/applogos/images/audio.png";
import video from "../../../images/applogos/images/video.png";
import { postRequest } from "../../../utils/api_helper";
import { MyContext } from "../../ContextMail";
import { toast } from "react-toastify";
import RepliableMail from "./RepliableMail";
import parse from "html-react-parser";
import moreImg from "../../../images/applogos/images/arrow_down.png";
import QuillEditor from "../../QuillEditor";
import formatEditor from "../../../images/applogos/images/formatEditor.png";
import VideoRecorder from "../../components/VideoRecorder";
import AudioRecorder from "../../components/AudioRecorder";

import Signature from "../../Signature";
import QuickInsert from "../../QuickInsert";
import { useNavigate } from "react-router";
import { GloabalContext } from "../../../layout/Index";
import Swal from "sweetalert2";

const tagifySettings = {
  // blacklist: ["xxx", "yyy", "zzz"],
  // maxTags: 6,
  backspace: "edit",
  addTagOnBlur: true,
  placeholder: "",
  dropdown: {
    classname: "color-blue",
    enabled: 1,
    // maxItems: 8,
    position: "text", // place the dropdown near the typed text
    closeOnSelect: true, // keep the dropdown open after selecting a suggestion
    highlightFirst: true,
    searchKeys: ["id", "text", "firstname", "lastname"],
  },
};

const findInvalidTags = () => {
  // Select all invalid tags
  const invalidTagElements = document.querySelectorAll(".tagify--notAllowed");
  // Map through selected elements and extract text from the span
  const invalidTagsArray = Array.from(invalidTagElements).map((tag) => {
    return tag.querySelector(".tagify__tag-text").textContent; // Get the text content
  });

  return invalidTagsArray;
};

function ReplyForm({ mailData, setMailData, messagesEndRef, getMail, replyingTo, isReplyAll }) {
  const navigate = useNavigate();
  const { suggestionMails, toggleMessageView, fetchTabData, setRefresh } = useContext(MyContext);
  const { storageDetails, refetch } = useContext(GloabalContext);

  // const { storageDetails, loading, error } = useStorageUsage();
  const [uploadPromise, setUploadPromise] = useState(null);

  // const [suggestionMails, setSuggestionMails] = useState([]);
  //   const [mailData, setMailData] = useState();
  const [inboxText, setInboxText] = useState("");
  const [attachmentList, setAttachmentList] = useState([]);
  const [onEditor, setOnEditor] = useState(true);
  const onEditorTool = () => {
    setOnEditor(!onEditor);
  };

  const [tagifyOptions, setTagifyOptions] = useState({
    toggle: true,
    value: [""],
  });
  const [invalidTo, setInvalidTo] = useState(true);

  const [ccTagify, setCCTagify] = useState({
    toggle: false,
    value: [""],
  });
  const [invalidCC, setInvalidCC] = useState(true);

  const [bccTagify, setBCCTagify] = useState({
    toggle: false,
    value: [""],
  });
  const [invalidBCC, setInvalidBCC] = useState(true);

  const [renderId, setRenderId] = useState(1);

  const [selectedReply, setSelectedReply] = useState(null);

  const [isReplyVisible, setIsReplyVisible] = useState(false);

  const [messageState, setMessageState] = useState(false);
  let [recordOption, setRecordOption] = useState(null);

  const toggleRecordOption = (type) => {
    setRecordOption(type);
  };

  const toggleMessageModal = () => {
    setMessageState(!messageState);
    // setRecordOption(null);
  };

  const toggleReplyVisibility = () => {
    setIsReplyVisible(!isReplyVisible);
  };

  useEffect(() => {
    setSelectedReply(mailData.message.reply.find((reply) => reply.replyId === replyingTo));
  }, [replyingTo]);

  useEffect(() => {
    if (selectedReply) {
      const userName = getCookie("username");

      // Check if it's Reply All
      if (isReplyAll) {
        const { recipient = [], cc = [], bcc = [] } = selectedReply.to;

        let toRecipients = [];
        let isSender = selectedReply.name === userName;

        if (!isSender) {
          // If the email is not from the current user, add the sender's email first
          toRecipients = [
            { value: selectedReply.mail },
            ...recipient
              .filter(({ name }) => name !== userName) // Exclude the current user
              .map(({ mail }) => ({
                value: mail,
              })),
          ];
        } else {
          // If the email is from the current user, just add recipients
          toRecipients = recipient
            .filter(({ name }) => name !== userName) // Exclude the current user
            .map(({ mail }) => ({
              value: mail,
            }));

          // If the email is sent only to the current user, include the current user's email
          if (toRecipients.length === 0) {
            toRecipients = [{ value: selectedReply.mail }];
          }
        }

        setTagifyOptions({
          toggle: true,
          value: toRecipients,
        });
        setInvalidTo(false);

        // Handle "cc" recipients, resetting if there are none
        const ccRecipients = cc
          .filter(({ name }) => name !== userName)
          .map(({ mail }) => ({
            value: mail,
          }));

        if (ccRecipients.length === 0) {
          setCCTagify({
            toggle: false,
            value: [""],
          });
          setInvalidCC(true);
        } else {
          setCCTagify({
            toggle: true,
            value: ccRecipients,
          });
          setInvalidCC(false);
        }

        // Handle "bcc" recipients, ignoring the current user's email
        const bccRecipients = bcc
          .filter(({ name }) => name !== userName)
          .map(({ mail }) => ({
            value: mail,
          }));

        if (bccRecipients.length === 0) {
          setBCCTagify({
            toggle: false,
            value: [""],
          });
          setInvalidBCC(true);
        } else {
          setBCCTagify({
            toggle: true,
            value: bccRecipients,
          });
          setInvalidBCC(false);
        }
      } else {
        // Standard reply (not Reply All)
        setTagifyOptions({
          toggle: true,
          value: [
            {
              value:
                selectedReply.name === userName
                  ? selectedReply.to.recipient[0]?.mail || selectedReply.mail
                  : selectedReply.mail,
            },
          ],
        });
        setInvalidTo(false);

        setCCTagify({
          toggle: false,
          value: [""],
        });
        setInvalidCC(true);

        setBCCTagify({
          toggle: false,
          value: [""],
        });
        setInvalidBCC(true);
      }
    }
  }, [selectedReply, isReplyAll]);

  useEffect(() => {
    setSelectedReply(mailData.message.reply[mailData.message.reply.length - 1]);
  }, [mailData]);

  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      const scrollHeight = messagesEndRef.current.scrollHeight;
      const height = messagesEndRef.current.clientHeight;
      const maxScrollTop = scrollHeight - height;
      messagesEndRef.current.scrollTop = maxScrollTop > 0 ? maxScrollTop : 0;
    }
  };

  const resetForm = () => {
    setInboxText("");
    setBCCTagify({ toggle: false, value: [""] });
    setInvalidBCC(true);
    setCCTagify({ toggle: false, value: [""] });
    setInvalidCC(true);
    setAttachmentList([]);
    setOnEditor(true);
    setRenderId((prev) => prev + 1);

    messagesEndRef.current.style.scrollBehavior = "smooth";
    scrollToBottom();
  };

  const sendReply = async (id) => {
    // Gather all the necessary data first
    const recipients = invalidTo ? "" : tagifyOptions.value.map((item) => item.value);
    const cc = invalidCC ? "" : ccTagify.value.map((item) => item.value);
    const bcc = invalidBCC ? "" : bccTagify.value.map((item) => item.value);

    const data = new FormData();
    // data.append("id", id);
    data.append("mail_id", id);
    data.append("sender", getCookie("email"));
    data.append("recipients", recipients);
    data.append("cc", cc);
    data.append("bcc", bcc);
    data.append("message", inboxText);
    data.append("attachment", JSON.stringify(attachmentList));
    // attachmentList.forEach((file) => {
    //   data.append("attachment[]", file); // 'attachment[]' allows multiple files to be sent
    // });
    data.append("is_draft", 0);
    data.append("reply_id", replyingTo);
    // data.append("is_draft", type === "sent" ? 0 : 1);

    resetForm();

    // Using toast.promise for async call
    const sendPromise = postRequest("/mail/send-mail", data);

    toast.promise(sendPromise, {
      pending: "Replying...",
      success: "Reply sent.",
      error: "Reply sending failed.",
      autoClose: 5000,
    });

    try {
      const result = await sendPromise;
      if (result.status) {
        getMail(mailData.id, true);
        scrollToBottom();
        if (window.location.pathname.includes("/trash")) {
          toast.info("Mail restored!");
          navigate("/");
          toggleMessageView();
        }
      }
      // console.log(result);
    } catch (err) {
      throw err;
      // console.log(err);
    } finally {
      fetchTabData();
      setRefresh(true);
    }
  };

  const sendInbox = async () => {
    const invalidTags = findInvalidTags();

    if (invalidTags.length > 0) {
      // Create a formatted message for the invalid recipients
      const invalidTagsMessage = invalidTags.map((tag) => `<strong>${tag}</strong>`).join(", ");

      // Show SweetAlert with invalid recipients
      await Swal.fire({
        title: "Invalid Recipients",
        html: `<p>The following recipients are not recognized:</p><p>${invalidTagsMessage}</p>`,
        icon: "warning",
        confirmButtonText: "OK",
        focusConfirm: false,
      });
      return; // Exit the function if there are invalid recipients
    }

    console.log(invalidTo, invalidCC, invalidBCC);

    if (invalidBCC && invalidCC && invalidTo) {
      toast.warn("Please add a recipient.");
      return;
    }

    const hasText = inboxText?.trim() !== "";

    if (!hasText) {
      const result = await Swal.fire({
        icon: "warning",
        text: "Send this message without text in the body?",
        showCancelButton: true,
        confirmButtonText: "Yes, send it!",
        cancelButtonText: "Cancel",
      });

      if (!result.isConfirmed) {
        return; // Exit if the user cancels the action
      }
    }

    let defaultData = mailData;
    let defaultReplyItem = defaultData.message.reply;

    const replyItemModel = {
      replyId: `${Math.floor(Math.random() * 10000)}`,
      userId: "",
      name: getCookie("username"),
      mail: getCookie("email"),
      utctime: new Date().toISOString(),
      theme: "warning",
      isRecipient: true,
      replyOf: replyingTo,
      to: {
        recipient: [{ name: "", mail: tagifyOptions.value[0].value }],
      },
      date: "",
      time: "",
      replyMessage: inboxText,
      sending: true,
    };

    let addedReplyIndex = defaultReplyItem.length;

    try {
      if (uploadPromise) {
        try {
          await toast.promise(uploadPromise, {
            pending: "Uploading files, please wait...",
            success: "Files uploaded! Ready to send.",
          });
        } catch (error) {
        } finally {
          setUploadPromise(null);
          return;
        }
      }

      // Add the dummy reply to the list
      defaultReplyItem.push(replyItemModel);
      setMailData({ ...defaultData });

      // Call the sendReply function
      await sendReply(mailData.id);
    } catch (error) {
      // If the sendReply function fails, remove the dummy reply from the array
      defaultReplyItem.splice(addedReplyIndex, 1); // Remove the last added dummy reply
      setMailData({ ...defaultData }); // Update the state to reflect the removal
    }
  };

  const onTagifyChange = (e) => {
    console.log(1, invalidTo, e);
    try {
      let arrayVal = JSON.parse(e.detail.value);
      setTagifyOptions({ toggle: true, value: arrayVal });
      setInvalidTo(false);
    } catch (error) {
      // console.error("Failed to parse JSON or handle tagify options:", error);
      if (e.detail.value === "") {
        setInvalidTo(true);
      }
    }
  };

  const onCcChange = (e) => {
    // console.log(2, invalidCC, e);
    try {
      let arrayVal = JSON.parse(e.detail.value);
      setCCTagify({ toggle: true, value: arrayVal });
      setInvalidCC(false);
    } catch (error) {
      // console.error("Failed to parse JSON or handle CC tagify options:", error);
      if (e.detail.value === "") {
        setInvalidCC(true);
      }
    }
  };

  const onBccChange = (e) => {
    // console.log(3, invalidBCC, e);
    try {
      let arrayVal = JSON.parse(e.detail.value);
      setBCCTagify({ toggle: true, value: arrayVal });
      setInvalidBCC(false);
    } catch (error) {
      // console.error("Failed to parse JSON or handle BCC tagify options:", error);
      if (e.detail.value === "") {
        setInvalidBCC(true);
      }
    }
  };

  const onAttachmentClick = () => {
    if (storageDetails.percentageUsed >= 100) {
      showStorageAlert();
      return;
    }

    const inputEl = document.getElementById("rep-attachmentInput");
    inputEl.click();
    inputEl.onchange = onAttachmentChange;
  };

  const onAttachmentChange = async (ev) => {
    if (ev.target.files.length > 0) {
      const files = Array.from(ev.target.files); // Convert FileList to an array
      // Add files to the state with loading: true
      const tempFiles = files.map((file) => ({
        name: file.name,
        size: file.size,
        shortpath: file.name,
        path: URL.createObjectURL(file),
        loading: true, // Temporarily mark as loading
      }));

      setAttachmentList((prevFiles) => [...prevFiles, ...tempFiles]); // Add to the state

      // Now, upload the actual files
      const upload = uploadFiles(files, tempFiles);
      setUploadPromise(upload);
      try {
        await upload;
      } catch (error) {
      } finally {
        setUploadPromise(null);
      }
    }
  };

  const onImageClick = () => {
    if (storageDetails.percentageUsed >= 100) {
      showStorageAlert();
      return;
    }

    const inputEl = document.getElementById("rep-imageInput");
    inputEl.click();
    inputEl.onchange = onImageChange;
  };

  const onImageChange = async (ev) => {
    if (ev.target.files.length > 0) {
      const files = Array.from(ev.target.files); // Convert FileList to an array
      // Add files to the state with loading: true
      const tempFiles = files.map((file) => ({
        name: file.name,
        size: file.size,
        shortpath: file.name,
        path: URL.createObjectURL(file),
        loading: true, // Temporarily mark as loading
      }));

      setAttachmentList((prevFiles) => [...prevFiles, ...tempFiles]); // Add to the state

      // Now, upload the actual files
      const upload = uploadFiles(files, tempFiles);
      setUploadPromise(upload);
      try {
        await upload;
      } catch (error) {
      } finally {
        setUploadPromise(null);
      }
    }
  };

  const handleVideoRecorded = async (blob) => {
    if (blob) {
      // Create a temporary file-like object for the video blob
      const videoFile = new File([blob], "recorded-video.webm", { type: blob.type });

      // Create a temp file object similar to how you're doing it with ev.target.files
      const tempFile = {
        name: videoFile.name,
        size: videoFile.size,
        shortpath: videoFile.name,
        path: URL.createObjectURL(videoFile), // Create an object URL for the blob
        loading: true, // Mark as loading temporarily
      };

      // Add the video to the attachment list
      setAttachmentList((prevFiles) => [...prevFiles, tempFile]);

      // Now handle the file upload process if needed
      const upload = uploadFiles([videoFile], [tempFile]);
      setUploadPromise(upload);
      try {
        await upload;
      } catch (error) {
      } finally {
        setUploadPromise(null);
      }
    }
  };

  const handleAudioRecorded = async (blob) => {
    if (blob) {
      // Create a temporary file-like object for the audio blob
      const audioFile = new File([blob.blob], "recorded-audio.wav", { type: blob.blob.type });

      // Create a temp file object similar to how you're doing it with ev.target.files
      const tempFile = {
        name: audioFile.name,
        size: audioFile.size,
        shortpath: audioFile.name,
        path: URL.createObjectURL(audioFile), // Create an object URL for the blob
        loading: true, // Mark as loading temporarily
      };

      // Add the audio to the attachment list
      setAttachmentList((prevFiles) => [...prevFiles, tempFile]);

      // Now handle the file upload process if needed
      const upload = uploadFiles([audioFile], [tempFile]);
      setUploadPromise(upload);
      try {
        await upload;
      } catch (error) {
      } finally {
        setUploadPromise(null);
      }
    }
  };

  // const removeAttachment = (text) => {
  //   let defaultData = attachmentList;
  //   defaultData = defaultData.filter((item) => item.fileName !== text);
  //   setAttachmentList([...defaultData]);
  // };

  const settings = {
    ...tagifySettings,
    addTagOnBlur: true,
    pattern: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+\.[a-zA-Z]{2,}$/,
    // validate: (e) => {
    //   return validateEmail(e.value, e);
    // },
    duplicate: false,
    skipInvalid: false,
    keepInvalidTags: true,
    createInvalidTags: true,
    // callbacks: {
    //   add: beforeAddTag,
    // },
    editTags: {
      keepInvalid: false,
    },
  };

  const handleEditorChange = (html) => {
    // console.log("Editor content received:", html); // Log the full content
    setInboxText(html); // Store the full content in state
  };

  const uploadFiles = (files, tempFiles) => {
    return new Promise(async (resolve, reject) => {
      const data = new FormData();

      // Append each selected file to FormData
      files.forEach((file) => {
        data.append("attachment[]", file); // 'attachment[]' for multiple file upload
      });

      try {
        // Send the FormData with all the selected files
        const response = await postRequest("/mail/upload-attachment", data);

        // Check if the response is successful
        if (response.status) {
          refetch();
          const uploadedFilesData = response.data.map((file) => ({
            name: file.fileName, // Replace fileName with name
            size: file.size,
            shortpath: file.shortpath,
            path: file.path, // Use the provided file path
          }));

          // Replace the temporary files in the state with the actual uploaded file data
          setAttachmentList((prevFiles) => {
            // Remove temporary files that were marked as loading
            const filteredFiles = prevFiles.filter((file) => !tempFiles.includes(file));

            // Return the previously uploaded files + new files from the API response
            return [...filteredFiles, ...uploadedFilesData];
          });

          // Resolve the promise with success data
          resolve(uploadedFilesData);
        } else {
          throw new Error("Failed to upload files");
        }
      } catch (error) {
        // console.error("Error uploading files:", error);

        // Show error toast message
        // toast.error("Failed to upload files. Please try again.");

        // Remove the temporary file objects marked as loading
        setAttachmentList((prevFiles) => prevFiles.filter((file) => !tempFiles.includes(file)));

        showStorageAlert();

        // Reject the promise with an error
        reject(error);
      }
    });
  };

  const addSignature = (text) => {
    setRenderId((prev) => prev + 1);
    setInboxText((prev) => prev + "<br/>--" + text);
  };

  const addQuickInsert = (text) => {
    setRenderId((prev) => prev + 1);
    setInboxText((prev) => prev + "" + text);
  };

  return (
    <>
      {selectedReply && (
        <div className="nk-ibx-reply-form nk-reply-form bg-theme-dark mb-0 p-1" style={{ borderBottom: "none" }}>
          <div className="d-flex column-gap-2 align-items-center">
            <span>
              {formatReplyDateTime(selectedReply?.utctime)} <strong>{selectedReply?.name}</strong>{" "}
              <RepliableMail email={selectedReply?.mail} /> wrote:
            </span>
            <div className="d-inline pointer" onClick={toggleReplyVisibility} id={"tooltipr" + selectedReply?.replyId}>
              <img src={moreImg} height={5} />
            </div>
          </div>
          <UncontrolledTooltip autohide={true} placement={"top"} target={"tooltipr" + selectedReply?.replyId}>
            {isReplyVisible ? "Hide full content" : "Show trimmed content"}
          </UncontrolledTooltip>
          {isReplyVisible && (
            <p style={{ whiteSpace: "pre-wrap", borderLeft: "1px solid grey", paddingLeft: "8px", marginLeft: "5px" }}>
              <div style={{ whiteSpace: "pre-wrap" }}>{parse(selectedReply?.replyMessage)}</div>
            </p>
          )}
        </div>
      )}
      <div className="nk-ibx-reply-form nk-reply-form bg-theme-dark">
        <div className="nk-reply-form-header">
          <div className="nk-reply-form-group">
            <div className="nk-reply-form-dropdown">
              <UncontrolledDropdown>
                <DropdownToggle
                  tag="a"
                  href="#item"
                  onClick={(ev) => ev.preventDefault()}
                  className="btn btn-sm btn-trigger btn-icon"
                >
                  <Icon name="curve-up-left"></Icon>
                </DropdownToggle>
                {/* <DropdownMenu className="dropdown-menu-md">
                        <ul className="link-list-opt no-bdr">
                          <li>
                            <DropdownItem tag="a" href="#item" onClick={(ev) => ev.preventDefault()}>
                              <Icon name="reply-fill"></Icon> <span>Reply to Abu Bin Ishtiyak</span>
                            </DropdownItem>
                          </li>
                          <li>
                            <DropdownItem tag="a" href="#item" onClick={(ev) => ev.preventDefault()}>
                              <Icon name="forward-arrow-fill"></Icon> <span>Forword</span>
                            </DropdownItem>
                          </li>
                          <li className="divider"></li>
                          <li>
                            <DropdownItem tag="a" href="#item" onClick={(ev) => ev.preventDefault()}>
                              <Icon name="edit-fill"></Icon> <span>Edit Subject</span>
                            </DropdownItem>
                          </li>
                        </ul>
                      </DropdownMenu> */}
              </UncontrolledDropdown>
            </div>
            <div className="nk-reply-form-title d-sm-none">Reply</div>
            <div className="nk-reply-form-input-group">
              <div className="nk-reply-form-input nk-reply-form-input-to">
                <label className="label">To</label>
                <Tags
                  whitelist={suggestionMails}
                  className="input-mail"
                  mode="textarea"
                  value={tagifyOptions?.value}
                  onChange={(e) => onTagifyChange(e)}
                  settings={settings}
                  showDropdown={true}
                />
              </div>
              <div className={`nk-reply-form-input nk-reply-form-input-cc ${ccTagify.toggle ? "expanded" : ""}`}>
                <label className="label">Cc</label>
                <Tags
                  whitelist={suggestionMails}
                  className="input-mail"
                  mode="textarea"
                  value={ccTagify?.value}
                  onChange={(e) => onCcChange(e)}
                  settings={settings}
                  showDropdown={true}
                />
                <a
                  className="toggle-opt active"
                  href="#item"
                  onClick={(ev) => {
                    ev.preventDefault();
                    setCCTagify({ value: [""], toggle: false });
                  }}
                >
                  <Icon name="cross"></Icon>
                </a>
              </div>
              <div className={`nk-reply-form-input nk-reply-form-input-bcc ${bccTagify.toggle ? "expanded" : ""}`}>
                <label className="label">Bcc</label>
                <Tags
                  whitelist={suggestionMails}
                  className="input-mail"
                  mode="textarea"
                  value={bccTagify?.value}
                  onChange={(e) => onBccChange(e)}
                  settings={settings}
                  showDropdown={true}
                />
                <a
                  className="toggle-opt active"
                  href="#item"
                  onClick={(ev) => {
                    ev.preventDefault();
                    setBCCTagify({ value: [""], toggle: false });
                  }}
                >
                  <Icon name="cross"></Icon>
                </a>
              </div>
            </div>
            <ul className="nk-reply-form-nav">
              <li>
                {!ccTagify.toggle && (
                  <a
                    className="toggle-opt"
                    href="#item"
                    onClick={(ev) => {
                      ev.preventDefault();
                      setCCTagify({ ...ccTagify, toggle: true });
                    }}
                  >
                    CC
                  </a>
                )}
              </li>
              <li>
                {!bccTagify.toggle && (
                  <a
                    className="toggle-opt"
                    href="#item"
                    onClick={(ev) => {
                      ev.preventDefault();
                      setBCCTagify({ ...bccTagify, toggle: true });
                    }}
                  >
                    BCC
                  </a>
                )}
              </li>
            </ul>
          </div>
        </div>
        <div className="nk-reply-form-editor">
          <div className="nk-reply-form-field p-0 position-relative">
            <QuillEditor
              key={renderId}
              placeholder="Enter reply..."
              theme="snow"
              modules={{
                toolbar: [
                  [{ header: [1, 2, 3, 4, false] }],
                  [{ font: [] }],
                  [{ align: [] }],
                  ["bold", "italic", "underline", "strike"],
                  [{ color: [] }, { background: [] }],
                  [{ list: "ordered" }, { list: "bullet" }],
                  [{ indent: "-1" }, { indent: "+1" }],
                  [{ direction: "rtl" }],
                  ["blockquote", "code-block"],
                  ["link", "image", "video"],
                  ["clean"],
                ],
              }}
              formats={[
                "header",
                "font",
                "size",
                "align",
                "bold",
                "italic",
                "underline",
                "strike",
                "blockquote",
                "code-block",
                "list",
                "bullet",
                "indent",
                "color",
                "background",
                "link",
                "image",
                "video",
                "direction",
                "script",
                "formula",
                "clean",
              ]}
              onChange={handleEditorChange} // Pass the handler directly
              value={inboxText} // Pass the current content from state to Quill
              attachmentList={attachmentList}
              setAttachmentList={setAttachmentList}
              onEditor={onEditor}
            />
            {/* <textarea
              className="form-control form-control-simple no-resize"
              placeholder="Reply..."
              value={inboxText}
              onChange={(e) => setInboxText(e.target.value)}
            ></textarea> */}
          </div>
        </div>
        {/* <div className="nk-reply-form-attachment">
          {attachmentList.map((item, index) => (
            <div key={index} className="nk-reply-form-attachment-list p-1 align-center justify-between m-2 d-flex">
              <span>{item.fileName}</span>
              <a
                className="toggle-opt"
                href="remove"
                onClick={(ev) => {
                  ev.preventDefault();
                  removeAttachment(item.fileName);
                }}
              >
                <Icon name="cross"></Icon>
              </a>
            </div>
          ))}
        </div> */}
        <div className="nk-reply-form-tools">
          <ul className="nk-reply-form-actions g-1">
            <li className="me-2">
              <Button color="primary" type="submit" onClick={() => sendInbox()}>
                Send
              </Button>
            </li>
            {/* <li>
              <UncontrolledDropdown>
                <DropdownToggle
                  tag="a"
                  className="btn btn-icon btn-sm btn-tooltip"
                  href="#toggle"
                  onClick={(ev) => ev.preventDefault()}
                >
                  <Icon name="hash"></Icon>
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-md">
                  <ul className="link-list-opt no-bdr link-list-template">
                    <li className="opt-head">
                      <span>Quick Insert</span>
                    </li>
                    {templateList.map((item) => (
                      <li key={item.id}>
                        <DropdownItem
                          href="#item"
                          tag="a"
                          onClick={(ev) => {
                            ev.preventDefault();
                            setInboxText(inboxText + item.text);
                          }}
                        >
                          <span className="text-ellipsis">{item.text}</span>
                        </DropdownItem>
                      </li>
                    ))}
                    <li className="divider"></li>
                    <li onClick={() => saveTemplate()}>
                      <DropdownItem tag="a" href="#item" onClick={(ev) => ev.preventDefault()}>
                        <Icon name="file-plus"></Icon>
                        <span>Save as Template</span>
                      </DropdownItem>
                    </li>
                  </ul>
                </DropdownMenu>
              </UncontrolledDropdown>
            </li> */}
            <li>
              <QuickInsert onSendMessage={addQuickInsert} />
            </li>

            <li>
              <Signature onSendData={addSignature} />
            </li>

            <li onClick={() => onAttachmentClick()}>
              <TooltipComponent
                tag="a"
                containerClassName="btn btn-icon btn-sm"
                // icon="clip-v"
                path={attache}
                id="ibx-msg-attachment"
                direction="top"
                text="Upload Attachment"
              />
              <input type="file" id="rep-attachmentInput" multiple style={{ display: "none" }}></input>
            </li>
            <li onClick={() => onImageClick()}>
              <TooltipComponent
                tag="a"
                containerClassName="btn btn-icon btn-sm"
                // icon="img"
                path={image}
                id="ibx-msg-images"
                direction="top"
                text="Upload Images"
              />
              <input
                type="file"
                id="rep-imageInput"
                accept=".png, .jpg, .jpeg, .gif, .bmp, .svg, .webp, .tiff" // Support various image formats
                multiple // Allow selecting multiple files
                style={{ display: "none" }}
              />
            </li>
            <li>
              <div
                onClick={() => {
                  if (storageDetails.percentageUsed >= 100) {
                    showStorageAlert();
                    return;
                  }
                  toggleRecordOption("video");
                  setMessageState(true);
                }}
              >
                <TooltipComponent
                  tag="a"
                  containerClassName="btn btn-icon btn-sm"
                  // icon="video"
                  path={video}
                  id="ibx-video-message"
                  direction="top"
                  text="Record Video"
                />
              </div>
            </li>

            <li>
              {/* <li className="d-none"> */}
              <div
                onClick={() => {
                  if (storageDetails.percentageUsed >= 100) {
                    showStorageAlert();
                    return;
                  }
                  toggleRecordOption("audio");
                  setMessageState(true);
                }}
              >
                <TooltipComponent
                  tag="a"
                  containerClassName="btn btn-icon btn-sm"
                  icon="mic"
                  path={audio}
                  id="ibx-audio-message"
                  direction="top"
                  text="Record Audio"
                />
              </div>
            </li>

            <li onClick={() => onEditorTool()}>
              <TooltipComponent
                tag="a"
                containerClassName="btn btn-icon btn-sm"
                // icon="img"
                path={formatEditor}
                id="editor"
                direction="top"
                text="Formating options"
              />
            </li>
          </ul>
        </div>
      </div>

      <Modal isOpen={messageState} className="modal-lg" togglemessage={() => toggleMessageModal()}>
        <div className="modal-header">
          <h6 className="modal-title">{recordOption === "video" ? "Video Recorder" : "Audio Recorder"}</h6>
          <a
            href="#cancel"
            onClick={(ev) => {
              ev.preventDefault();
              // addToDraft();
              toggleMessageModal();
            }}
            className="close"
          >
            <Icon name="cross-sm"></Icon>
          </a>
        </div>
        <div className="modal-body p-5">
          {recordOption && (
            <div className="">
              {recordOption === "video" ? (
                <VideoRecorder
                  onVideoRecorded={handleVideoRecorded}
                  toggleMessageModal={toggleMessageModal}
                  isModalOpen={messageState}
                />
              ) : recordOption === "audio" ? (
                <AudioRecorder
                  onAudioRecorded={handleAudioRecorded}
                  toggleMessageModal={toggleMessageModal}
                  isModalOpen={messageState}
                />
              ) : null}
            </div>
          )}
        </div>
      </Modal>
    </>
  );
}

export default ReplyForm;

import React, { useContext, useEffect, useRef } from "react";
import Quill from "quill";
import "quill/dist/quill.snow.css";
import "./sidebarmenu/QuillEditor.css"; // Import custom CSS
import { MyContext } from "./ContextMail";
import { Icon } from "../components/Component";
import { Spinner } from "reactstrap";
import { postRequest } from "../utils/api_helper";
import ImageUploader from "quill-image-uploader";
import ImageResize from "quill-image-resize-module-react";
import { toast } from "react-toastify";
import { Attributor } from "parchment";
import "quill-image-uploader/dist/quill.imageUploader.min.css";
import { GloabalContext } from "../layout/Index";
const fonts = ["Arial", "Georgia", "Impact", "Tahoma", "Times New Roman", "Verdana"];
var Font = Quill.import("formats/font");
Font.whitelist = fonts;
Quill.register(Font, true);

// Custom ImageBlot for handling image attributes and resizing
const BlockEmbed = Quill.import("blots/block/embed");
class ImageBlot extends BlockEmbed {
  static create(value) {
    let node = super.create();
    node.setAttribute("src", value.src || value);
    node.setAttribute("alt", value.alt || "image");
    node.setAttribute("style", value.style || "max-width:100%;");
    return node;
  }

  static value(node) {
    return {
      src: node.getAttribute("src"),
      alt: node.getAttribute("alt"),
      style: node.getAttribute("style"),
    };
  }
}
ImageBlot.blotName = "imageBlot";
ImageBlot.tagName = "img";

// Register the modules
Quill.register("modules/imageUploader", ImageUploader);
Quill.register("modules/imageResize", ImageResize);
Quill.register(ImageBlot); // Register the custom ImageBlot

const QuillEditor = ({
  placeholder = "Compose an epic...",
  theme = "snow",
  modules = {},
  formats = [],
  onChange,
  value = "",
  readOnly = false,
  customStyles = {},
  attachmentList,
  setAttachmentList,
  onEditor,
}) => {
  const editorRef = useRef(null);
  const quillRef = useRef(null);
  const { refetch } = useContext(GloabalContext);

  useEffect(() => {
    if (!quillRef.current) {
      const quill = new Quill(editorRef.current, {
        theme,
        placeholder,
        modules: {
          ...modules,
          imageUploader: {
            upload: (file) => {
              return new Promise(async (resolve, reject) => {
                const data = new FormData();
                data.append("attachment[]", file);
                try {
                  const response = await postRequest("/mail/upload-attachment", data);
                  if (response.status) {
                    const uploadedFilesData = response.data.map((file) => ({
                      name: file.fileName,
                      size: file.size,
                      shortpath: file.shortpath,
                      path: file.path,
                    }));
                    resolve(uploadedFilesData[0].path);
                  } else {
                    throw new Error("Failed to upload files");
                  }
                } catch (error) {
                  toast.error("Failed to upload files. Please try again.");
                  reject(error);
                }
              });
            },
          },
          imageResize: {
            modules: ["Resize", "DisplaySize"],
          },
        },
        formats: [...formats, "imageBlot"],
        readOnly,
      });

      quillRef.current = quill;

      if (value) {
        quill.root.innerHTML = value;
      }

      quill.on("text-change", () => {
        const html = quill.root.innerHTML;
        if (onChange) {
          onChange(html);
        }
      });
    } else {
      if (value !== quillRef.current.root.innerHTML) {
        quillRef.current.root.innerHTML = value;
      }
    }

    return () => {
      if (quillRef.current) {
        quillRef.current.off("text-change");
      }
    };
  }, [theme, placeholder]);

  useEffect(() => {
    if (quillRef.current) {
      quillRef.current.root.innerHTML = value;
    }
  }, []);

  const removeAttachment = async (text) => {
    let defaultData = attachmentList;
    defaultData = defaultData.filter((item) => item.shortpath !== text);
    setAttachmentList([...defaultData]);

    const data = new FormData();
    data.append("attachment_path", text);

    try {
      const res = await postRequest("/mail/delete-attachment", data);
      if (res.status) refetch();
    } catch (error) {
      console.error("Error deleting files:", error);
    }
  };

  const tooltips = {
    ".ql-header": "Header",
    ".ql-font": "Font",
    ".ql-align": "Align",
    ".ql-bold": "Bold",
    ".ql-italic": "Italic",
    ".ql-underline": "Underline",
    ".ql-strike": "Strike",
    ".ql-color": "Text Color",
    ".ql-background": "Background Color",
    ".ql-list[value='ordered']": "Ordered List",
    ".ql-list[value='bullet']": "Bullet List",
    ".ql-indent[value='-1']": "Decrease Indent",
    ".ql-indent[value='+1']": "Increase Indent",
    ".ql-direction": "Text Direction",
    ".ql-blockquote": "Blockquote",
    ".ql-code-block": "Code Block",
    ".ql-link": "Insert Link",
    ".ql-image": "Insert Image",
    ".ql-video": "Insert Video",
    ".ql-clean": "Remove Formatting",
  };

  useEffect(() => {
    Object.keys(tooltips).forEach((selector) => {
      const elements = document.querySelectorAll(selector);
      elements.forEach((el) => {
        el.setAttribute("title", tooltips[selector]);
      });
    });
  }, []);

  return (
    <div style={customStyles} className={`quill-editor-container ${onEditor ? "editor-tool" : ""} `}>
      <div className={`nk-reply-form-attachment ${!onEditor ? "pb-editor" : ""}`}>
        {attachmentList?.map((item, key) => (
          <a
            href={item.path}
            target="_blank"
            key={key}
            className="nk-reply-form-attachment-list p-1 align-center justify-between m-2 d-flex"
          >
            <span>{item.name}</span>
            {item.loading ? (
              <Spinner size="sm" />
            ) : (
              <a
                className="toggle-opt"
                href="remove"
                onClick={(ev) => {
                  ev.preventDefault();
                  removeAttachment(item.shortpath);
                }}
              >
                <Icon name="cross"></Icon>
              </a>
            )}
          </a>
        ))}
      </div>
      <div ref={editorRef} className="quill-editor"></div>
    </div>
  );
};

export default QuillEditor;
